<script setup>
import { computed } from "vue";

// Lottie Animation
import { LottieAnimation } from "lottie-web-vue";
import solutionsAnimation from "/public/images/lottie/solutions.json";
import mobileAnimation from "/public/images/lottie/phone.json";

// Swiper
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Particle JS
import { loadSlim } from "tsparticles-slim";

const particlesInit = async engine => {
    await loadSlim(engine);
};

const options = computed(() => {
    return [
    {
        fpsLimit: 120,
        fullScreen: {
            enable: false
        },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 100
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 },
            }
        },
        detectRetina: true
    }]
});

const banners = [
    { 
        id: 1, 
        file: "solutions-banner-bg.png", 
        title: "Smart Customer Management, Smarter Business.", 
        titleStyle: "text-[#1F1F1F]", 
        description: "Simplify It!", 
        descriptionStyle: "bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent", 
        actionText: "Simplify Your Experience with AI Chatbot Solutions.",
        actionTextStyle: "text-[#8F8F8F]",
        actionImage: null, 
        buttonImage: "banner-btn4.png",
        position: "justify-center"
    },
    { 
        id: 2, 
        file: "homepage-banner-1.png", 
        title: "Increase sales with good customer service.", 
        titleStyle: "text-[#FFF]", 
        description: "Brew better customer relationships with a Chatbot!", 
        descriptionStyle: "text-[#FFF]",
        actionText: null,
        actionTextStyle: null,
        actionImage: "cta-1.png", 
        buttonImage: "banner-btn4.png",
        position: "justify-left xl:px-20"
    },
    { 
        id: 3, 
        file: "homepage-banner-2.png", 
        title: "Drive more sales with good customer management.", 
        titleStyle: "text-[#FFF]", 
        description: "That's why all business need better Chatbot!", 
        descriptionStyle: "text-[#FFF]",
        actionText: null,
        actionTextStyle: null,
        actionImage: "cta-1.png", 
        buttonImage: "banner-btn4.png",
        position: "justify-left xl:px-20"
    },
    { 
        id: 4, 
        file: "homepage-banner-3.png", 
        title: "Build higher sales with good customer management.", 
        titleStyle: "text-[#FFF]", 
        description: "Learn how an AI chatbot can be the foundation of an effective business.", 
        descriptionStyle: "text-[#FFF]",
        actionText: null,
        actionTextStyle: null,
        actionImage: "cta-1.png", 
        buttonImage: "banner-btn4.png",
        position: "justify-left xl:px-20"
    },
]
</script>

<template>
    <div class="flex flex-col">
        <!-- Home Banner -->
        <swiper
            :slides-per-view="1"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }"
            :loop="true"
            :pagination="true"
            :modules="modules"
            class="w-full xl:mt-[7rem]"
        >
            <swiper-slide v-for="banner in banners" :key="banner.id">
                <div 
                    :style="`background: url('images/homepage/${banner.file}') no-repeat; background-size: cover; background-position: center;`" 
                    :class="[banner.position, `relative flex items-center gap-6 w-full 2xl:h-[900px] lg:h-[750px] h-[600px]`]"
                >
                    <div class="flex flex-col justify-center md:text-center md:gap-10 gap-8 2xl:max-w-[60rem] max-w-[55rem] max-sm:p-6">
                        <div :class="[banner.titleStyle, `leading-tight font-extrabold 2xl:text-[50px] lg:text-[40px] text-[35px]`]">
                            {{ banner.title }}
                        </div>
                        <div :class="[banner.descriptionStyle, `font-bold 2xl:text-[35px] lg:text-[29px] text-[22px]`]">
                            {{ banner.description }}
                        </div>
                        <h1 
                            v-if="banner.actionText"
                            :class="[banner.actionTextStyle, `2xl:text-[26px] text-[20px]`]"
                        >
                            {{ banner.actionText }}
                        </h1>
                        <img 
                            v-if="banner.actionImage"
                            :src="`images/homepage/${banner.actionImage}`"
                        >
                        <div class="flex justify-center">
                            <img
                                v-if="banner.buttonImage"
                                :src="`images/homepage/${banner.buttonImage}`"
                            >
                        </div>
                        <!-- <a 
                            v-if="banner.id === 1"
                            href="https://app.smicos.com/register" 
                            class="flex justify-center" 
                            :class="`relative z-[${bannerZIndex}]`" target="_blank"
                        >
                            <span
                                class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-8 py-4 text-[18px] text-white font-semibold cursor-pointer"
                                :class="`relative z-[${bannerZIndex}]`"
                            >
                                Get Smicos for <span class="font-bold">FREE</span>
                            </span>
                        </a>  -->
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <!-- Feature Section -->
        <div class="relative flex md:flex-row flex-col items-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-8 z-20">
            <div class="absolute top-0 right-0 w-[50rem] h-full">
                <vue-particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :particlesLoaded="particlesLoaded"
                    :options="options"
                    style="height: 100%;"
                />
            </div>
            <div class="md:hidden block flex-1">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    style="height: 40rem;"
                />
            </div>
            <div class="flex-1 flex flex-col gap-10 2xl:pl-20">
                <h2 class="text-white text-center font-bold lg:text-[55px] md:text-[45px] text-[35px]">
                    We Understand<br> Your Business <span class="bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent font-extrabold">Needs</span>
                </h2>
                <LottieAnimation
                    :animation-data="solutionsAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="0.5"  
                    style="font-size: 40px;"
                />
                <div class="flex flex-wrap gap-14 items-center">
                    <div
                        v-for="solution in solutions"
                        class="flex items-center gap-1 md:w-[40%] w-full"
                    >
                        <component :is="solution.icon" class="w-7"/>
                        <div class="flex items-center">
                            <div class="w-5 h-5 bg-[#E37C0D] rounded-full solutions-bullet"></div>
                            <span class="relative -left-2 text-white font-bold">
                                {{ solution.title }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:block hidden flex-1 p-10">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    class="lg:h-[40rem]"
                />
            </div>
        </div>

        <!-- Transform -->
        <div class="flex flex-col items-center">
            <h2 
                class="font-extrabold lg:text-[45px] md:text-[40px] text-[30px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent 2xl:max-w-[50%] max-w-[90%] text-center z-20 pt-10 leading-tight"
            >
                Transform your business operations with Smicos chatbot solutions.
            </h2>
            <!-- Smicos Dashboard -->
            <div class="flex justify-center w-full section1-border-gradient xl:border-b-[200px] border-b-[150px]">
                <div class="relative 2xl:-bottom-[6rem] -bottom-[3rem] flex 2xl:flex-row flex-col items-start justify-between 2xl:gap-0 gap-10 max-w-[90%]">
                    <div class="2xl:hidden">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[32px] text-[28px]">
                                Smicos Dashboard
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Gain real-time insights into the effectiveness of your marketing campaigns with Smicos, empowering informed decision-making and optimization strategies.
                            </span>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <img
                            :src="`/images/solutions/sections/dashboard.png`"
                            class="w-full shadow-lg border rounded-lg"
                            alt="smicos dashboard"
                        >
                    </div>
                    <div class="w-[40%] 2xl:block hidden">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[32px] text-[28px]">
                                Smicos Dashboard
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Gain real-time insights into the effectiveness of your marketing campaigns with Smicos, empowering informed decision-making and optimization strategies.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Smicos Story Maker -->
            <div class="flex justify-center w-full section2-border-gradient xl:border-b-[200px] border-b-[150px]">
                <div class="relative 2xl:-bottom-[6rem] -bottom-[3rem] flex 2xl:flex-row flex-col items-start justify-between 2xl:gap-0 gap-10 max-w-[90%]">
                    <div class="2xl:w-[40%] w-full">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[30px] text-[26px]">
                                Smicos Story Maker
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Create conversation flows with simple, no-code needed, chat builder.
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos drives higher lead engagement and conversion rates through proactive and customized conversations and automated lead qualification, maximizing your sales opportunities.
                            </span>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <!-- <img
                            :src="`/images/solutions/sections/story-maker.gif`"
                            class="w-full shadow-lg border rounded-lg"
                            alt="smicos story maker"
                        > -->
                        <video 
                            autoplay loop muted
                            class="w-full shadow-lg border rounded-lg"
                        >
                            <source :src="`/images/solutions/sections/story-maker.webm`" type="video/mp4">
                        </video>
                    </div>
                </div>
            </div>
            <!-- Smicos Customer Database -->
            <div class="flex justify-center w-full section3-border-gradient xl:border-b-[200px] border-b-[150px]">
                <div class="relative 2xl:-bottom-[6rem] -bottom-[3rem] flex 2xl:flex-row flex-col items-start justify-between 2xl:gap-0 gap-10 max-w-[90%]">
                    <div class="2xl:hidden">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[32px] text-[28px]">
                                Smicos Customer Database
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos has a comprehensive leads database in one platform, enabling your sales team to focus on the most promising leads for faster conversions.
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos prioritizes the security and privacy of customer data, implementing robust encryption protocols and adhering to industry-standard compliance regulations.
                            </span>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <img
                            :src="`/images/solutions/sections/customer-database.png`"
                            class="w-full shadow-lg border rounded-lg"
                            alt="smicos story maker"
                        >
                    </div>
                    <div class="w-[40%] 2xl:block hidden">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[32px] text-[28px]">
                                Smicos Customer Database
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos has a comprehensive leads database in one platform, enabling your sales team to focus on the most promising leads for faster conversions.
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos prioritizes the security and privacy of customer data, implementing robust encryption protocols and adhering to industry-standard compliance regulations.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Smicos Ticketing Tool -->
            <div class="flex justify-center w-full section4-border-gradient xl:border-b-[200px] border-b-[150px]">
                <div class="relative 2xl:-bottom-[6rem] -bottom-[3rem] flex 2xl:flex-row flex-col items-start justify-between 2xl:gap-0 gap-10 max-w-[90%]">
                    <div class="2xl:w-[40%] w-full">
                        <div class="flex flex-col gap-2">
                            <span class="font-extrabold xl:text-[32px] text-[28px]">
                                Smicos Ticketing Tool
                            </span>
                            <span class="xl:text-[18px] text-[16px]">
                                Smicos efficiently logs, tracks, and manages customer issues, ensuring nothing falls through the cracks. With features like automated ticket assignment, status tracking, and priority handling, Smicos streamlines your support process, enabling your team to resolve issues promptly and keep your customers satisfied.
                            </span>
                        </div>
                    </div>
                    <div class="2xl:w-[55%] w-full">
                        <img
                            :src="`/images/solutions/sections/ticketing.png`"
                            class="w-full shadow-lg border rounded-lg"
                            alt="smicos story maker"
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.bg-orange-bullets{
    box-shadow: 0 0 90px 100px rgb(227, 124, 13, 1);
}

.bg-blue-bullets{
    box-shadow: 0 0 90px 100px rgba(0, 174, 238, 1);
}

.section1-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #F2CD59, #E37C0D);
}

.section2-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #E37C0D, #E1251B);
}

.section3-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #0A9EDF, #384590);
}

.section4-border-gradient {
    border-image-slice: 1;
    border-image-source: linear-gradient(to right, #E1251B, #E37C0D);
}
</style>
