<script setup>
import { ref, reactive } from "vue";
import {
    MapPinIcon,
    PhoneIcon,
    MailIcon
} from "@components/icons";

import AppointmentSetModal from "@/pages/contact-us/AppointmentSetModal.vue";

// Utils
const validateRules = (event) => {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
}

const formData = reactive({
    company_name: "",
    full_name: "",
    email: "",
    contact_no: "",
    subject: "",
    message: ""
});

const errors = ref({});

const submitInquiry = async () => {
    try {
        await window.axios.post(`/send-inquiry`, formData)
    } catch (error) {
        console.error(error);
        console.log(error);
        errors.value = error.response.data.errors;
    }
}

// Smicos connect modal
const appointmentModal = ref(false);
</script>

<template>
    <div class="flex flex-col">
        <!-- Contact Us Banner -->
        <div 
            :style="`background: url('images/homepage/solutions-banner-bg.png') no-repeat; background-size: cover; background-position: center;`" 
            class="relative flex flex-col items-center justify-center md:gap-6 gap-2 w-full 2xl:h-[800px] lg:h-[750px] h-[400px]"
        >   
            <span class="md:text-[38px] text-[24px] font-semibold">Contact Us</span>
            <span class="md:text-[75px] text-[40px] font-extrabold bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                INQUIRE NOW
            </span>
            <span class="md:text-[28px] text-[20px]">Got questions? Get in touch with us!</span>
        </div>

        <!-- Information Section -->
        <div class="flex flex-col items-center justify-center bg-gradient-to-r from-[#31549D] to-[#0A9EDF] md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col md:gap-20 gap-10 md:max-w-[80%] w-full mb-10">
                <div class="flex flex-col gap-4 w-full">
                    <h6 class="bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent font-extrabold text-[40px] md:text-left text-center">
                        Information
                    </h6>
                    <div class="flex items-start gap-4 text-[#FFF]">
                        <div class="w-[2rem]">
                            <MapPinIcon class="w-10"/>
                        </div>
                        <span class="text-[18px] font-semibold">
                            Unit 806, Antel 2000 Corporate Center, Valero St., Salcedo Village, Makati City, 1226, Philippines
                        </span>
                    </div>
                    <div class="flex items-center gap-4 text-[#FFF]">
                        <div class="w-[2rem]">
                            <PhoneIcon class="w-10"/>
                        </div>
                        <span class="text-[18px] font-semibold">
                            +63 9178362680
                        </span>
                    </div>
                    <div class="flex items-center gap-4 text-[#FFF]">
                        <div class="w-[2rem]">
                            <MailIcon class="w-10"/>
                        </div>
                        <span class="text-[18px] font-semibold">
                            inquiries@smicos.com
                        </span>
                    </div>
                    <div class="flex items-center gap-4 text-[#FFF]">
                        <div class="w-[2rem]">
                            <MailIcon class="w-10"/>
                        </div>
                        <span class="text-[18px] font-semibold">
                            marketing@smicos.com
                        </span>
                    </div>
                </div>
                <div class="flex flex-col gap-4 w-full">
                    <div class="flex flex-col gap-4 p-8 bg-white rounded-md shadow-md w-full md:max-w-[70%] h-full">
                        <h6 class="font-bold text-[26px]">
                            GET IN TOUCH WITH US
                        </h6>
                        <div class="flex flex-col gap-6">
                            <div class="relative">
                                <input
                                    v-model="formData.company_name"
                                    type="text"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 focus:outline-smicos-primary"
                                    :class="errors.company_name && `border border-solid border-smicos-secondary`"
                                    placeholder="Company Name"
                                    maxlength="100"
                                >
                                <span
                                    v-if="errors.company_name"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.company_name[0] }}
                                </span>
                            </div>
                            <div class="relative">
                                <input
                                    v-model="formData.full_name"
                                    type="text"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 focus:outline-smicos-primary"
                                    placeholder="Full Name"
                                    maxlength="50"
                                >
                                <span
                                    v-if="errors.full_name"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.full_name[0] }}
                                </span>
                            </div>
                            <div class="relative">
                                <input
                                    v-model="formData.email"
                                    type="email"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 focus:outline-smicos-primary"
                                    placeholder="Email"  
                                    maxlength="50"
                                >
                                <span
                                    v-if="errors.email"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.email[0] }}
                                </span>
                            </div>
                            <div class="relative">
                                <input
                                    v-model="formData.contact_no"
                                    @input="validateRules"
                                    type="text"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 focus:outline-smicos-primary"
                                    placeholder="Contact Number"
                                    maxlength="30"
                                >
                                <span
                                    v-if="errors.contact_no"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.contact_no[0] }}
                                </span>
                            </div>
                            <div class="relative">
                                <input
                                    v-model="formData.subject"
                                    type="text"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 focus:outline-smicos-primary"
                                    placeholder="Subject"
                                    maxlength="50"
                                >
                                <span
                                    v-if="errors.subject"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.subject[0] }}
                                </span>
                            </div>
                            <div class="relative">
                                <textarea
                                    v-model="formData.message"
                                    type="text"
                                    class="w-full border border-solid border-slate-400 rounded-md p-2 resize-none focus:outline-smicos-primary"
                                    rows="6"
                                    placeholder="Message"
                                ></textarea>
                                <span
                                    v-if="errors.message"
                                    class="absolute top-[100%] left-0 text-smicos-secondary text-sm font-bold"
                                >
                                    {{ errors.message[0] }}
                                </span>
                            </div>
                            <button
                                @click="submitInquiry"
                                class="bg-smicos-primary hover:opacity-70 rounded-md py-2 w-full text-white"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center w-full">
                <div class="flex md:flex-row flex-col md:gap-20 gap-10 md:max-w-[80%] w-full">
                    <div class="w-full">
                        <div class="font-extrabold md:text-[40px] text-[34px] md:text-left text-left bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent md:w-[30rem] leading-tight">
                            Get a Personalized Onboarding with Smicos Connect!
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-center md:max-w-[70%] w-full">
                            <div class="flex justify-center md:w-[430px]">
                                <button
                                    @click="appointmentModal = true"
                                    class="bg-smicos-primary rounded-md py-6 md:px-[5rem] px-[2rem] text-white font-bold md:text-[20px] text-[18px]"
                                >
                                    Book An Appointment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Google connect -->
        <AppointmentSetModal 
            v-if="appointmentModal"
            @close="appointmentModal = false"
        />
    </div>
</template>