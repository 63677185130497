<script setup>
import { ref, computed, reactive, watch } from "vue";
import { useAuthStore } from "@/stores/Auth";
import {
    EyeOpenIcon,
    EyeClosedIcon
} from "@components/icons";

import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css';

import CustomSelectInput from "@components/includes/CustomSelectInput.vue";

const props=defineProps(['academy']);
const emits = defineEmits(['register', 'show-login-form']);

const storeAuth = useAuthStore();

// Form data
const formData = reactive({
    first_name: "",
    last_name: "",
    contact_number: "",
    company_name: "",
    company_position: "",
    company_nature_of_business: "Agriculture",
    company_size: "1-10 employees",
    company_purpose: "Smicos Academy",
    discovered_thru: "Facebook",
    email: "",
    password: "",
    password_confirmation: "",
    device_name: "browser"
});

const errors = ref({});

const handleCreate = async () => {
    try {
        formData.training = {
            id: props.academy.id,
            title: props.academy.title,
            slug: props.academy.slug,
            path: props.academy.fullUrlPath,
            published_date: props.academy.published_date,
        }
        
        const res = await window.axios.post('https://app.smicos.com/api/website/sanctum/register', formData);
        storeAuth.auth = res.data.user;
        storeAuth.auth.api_token = res.data.token;
        localStorage.setItem("user", JSON.stringify(storeAuth.auth));
        emits('register');
    } catch (error) {
        errors.value = error.response.data.errors;
        console.error(error);
    }
}

const isAgree = ref(false);

const showPassword = ref(false);
const showConfirmPassword = ref(false);

const industries = [
    "Agriculture",
    "Apparel & Fashion",
    "Automotive",
    "Banking",
    "Biotechnology",
    "Chemicals",
    "Construction",
    "Consulting",
    "Education",
    "Electronics",
    "Energy",
    "Engineering",
    "Entertainment",
    "Environmental",
    "Finance",
    "Food & Beverage",
    "Government",
    "Healthcare",
    "Hospitality",
    "Insurance",
    "Manufacturing",
    "Marketing",
    "Media",
    "Non-profit",
    "Pharmaceuticals",
    "Real Estate",
    "Retail",
    "Software",
    "Technology",
    "Telecommunications",
    "Transportation",
    "Utilities"
];

const purposes = [
    "Customer Support",
    "Technical Assistance",
    "Marketing",
    "Sales Inquiry",
    "Product Demo",
    "Research",
    "Switching to another AI chatbot",
    "Looking for alternatives",
    "Smicos Academy"
];

const companySize = [
    "1-10 employees",
    "11-50 employees",
    "51-200 employees",
    "201-500 employees",
    "501-1000 employees",
    "1001-5000 employees",
    "5001-10,000 employees",
    "10,001+ employees",
];

const platforms = [
    "facebook",
    "instagram",
    "linkedin",
    "google/search engine",
    "peer referral",
    "blog or publication"
];

const isPasswordFocused = ref(false);
const isConfirmPasswordFocused = ref(false);

const passwordRequirements = ref([
    {
        id: 1,
        description: 'The password field must be at least 8 characters.',
        condition: false
    },
    {
        id: 2,
        description: 'The password field must contain at least one uppercase and one lowercase letter.',
        condition: false
    },
    {
        id: 3,
        description: 'The password field must contain at least one symbol.',
        condition: false
    },
    {
        id: 4,
        description: 'The password field must contain at least one number.',
        condition: false
    }
]);

const allRequirementsMet = computed(() => {
    return passwordRequirements.value.every(req => req.condition);
});

const isSignUpEnabled = computed(() => {
    return isAgree.value && formData.password && formData.password === formData.password_confirmation;
});

watch(
    () => formData.password,
    (password) => {
        passwordRequirements.value[0].condition = password.length > 8;
        passwordRequirements.value[1].condition = /[a-z]/.test(password) && /[A-Z]/.test(password);
        passwordRequirements.value[2].condition = /[^\w]/.test(password);
        passwordRequirements.value[3].condition = /[\d]/.test(password);
    }
)
</script>

<template>
    <div class="flex-1 flex flex-col gap-6 p-10 rounded-md border shadow-md max-w-[50rem]">
        <span class="font-extrabold leading-tight text-[25px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent mb-8">
            Fill up the form
        </span>
        <div class="flex items-center gap-10 w-full">
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    First name
                </span>
                <input 
                    v-model="formData.first_name"
                    type="text" 
                    placeholder="First name" 
                    class="focus:outline-smicos-primary p-2 rounded-md w-full"
                    :class="errors.first_name ? `ring-1 ring-smicos-secondary border-none` : `border`"
                >
                <span
                    v-if="errors.first_name" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.first_name[0] }}
                </span>
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Last name
                </span>
                <input 
                    v-model="formData.last_name"
                    type="text" 
                    placeholder="Last name" 
                    class="focus:outline-smicos-primary p-2 rounded-md w-full"
                    :class="errors.last_name ? `ring-1 ring-smicos-secondary border-none` : `border`"
                >
                <span
                    v-if="errors.last_name" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.last_name[0] }}
                </span>
            </div>
        </div>
        <div class="flex items-center gap-10 w-full">
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Contact Number
                </span>
                <VueTelInput 
                    v-model="formData.contact_number"
                    :validCharactersOnly="true"
                    :styleClasses="errors.contact_number ? `!border-[1px] !border-smicos-secondary !rounded-md py-1` : `!border-[1px] !rounded-md py-1`"
                />
                <span
                    v-if="errors.contact_number" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.contact_number[0] }}
                </span>
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Company name
                </span>
                <input 
                    v-model="formData.company_name"
                    type="text" 
                    placeholder="Company name" 
                    class="focus:outline-smicos-primary p-2 rounded-md w-full"
                    :class="errors.company_name ? `ring-1 ring-smicos-secondary border-none` : `border`"
                >
                <span
                    v-if="errors.company_name" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.company_name[0] }}
                </span>
            </div>
        </div>
        <div class="flex items-center gap-10 w-full">
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Position
                </span>
                <input 
                    v-model="formData.company_position"
                    type="text" 
                    placeholder="Position" 
                    class="focus:outline-smicos-primary p-2 rounded-md w-full"
                    :class="errors.company_position ? `ring-1 ring-smicos-secondary border-none` : `border`"
                >
                <span
                    v-if="errors.company_position" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.company_position[0] }}
                </span>
            </div>
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Nature of business
                </span>
                <CustomSelectInput 
                    :value="formData.company_nature_of_business"
                    :items="industries"
                    @update-selected="formData.industry = $event"
                />
            </div>
        </div>
        <div class="flex items-center gap-10 w-full">
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Size of the Company
                </span>
                <CustomSelectInput 
                    :value="formData.company_size"
                    :items="companySize"
                    @update-selected="formData.companySize = $event"
                />
            </div>
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Purpose
                </span>
                <CustomSelectInput 
                    :value="formData.company_purpose"
                    :items="purposes"
                    @update-selected="formData.purpose = $event"
                />
            </div>
        </div>
        <div class="flex items-center gap-10 w-full">
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    How did you find about Smicos?
                </span>
                <CustomSelectInput 
                    :value="formData.discovered_thru"
                    :items="platforms"
                    @update-selected="formData.platform = $event"
                />
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Email
                </span>
                <input 
                    v-model="formData.email"
                    type="email" 
                    placeholder="Email" 
                    class="focus:outline-smicos-primary p-2 rounded-md w-full"
                    :class="errors.email ? `ring-1 ring-smicos-secondary border-none` : `border`"
                >
                <span
                    v-if="errors.email" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.email[0] }}
                </span>
            </div>
        </div>
        <div class="flex items-center gap-10 w-full">
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Password
                </span>
                <div class="flex items-center">
                    <div class="relative flex items-center w-full">
                        <input 
                            v-model="formData.password"
                            :type="showPassword ? `text` : `password`"
                            placeholder="Password" 
                            class="focus:outline-smicos-primary p-2 rounded-md w-full"
                            :class="errors.password ? `ring-1 ring-smicos-secondary border-none` : `border`"
                            @focus="isPasswordFocused = true"
                            @blur="isPasswordFocused = false"
                        >
                        <button
                            @click="showPassword = !showPassword"
                            class="absolute text-gray-600 right-2"
                        >
                            <EyeOpenIcon
                                v-if="!showPassword"
                                class="w-4 h-4"
                            />
                            <EyeClosedIcon v-else class="w-4 h-4" />
                        </button>
                    </div>
                </div>
                <span
                    v-if="errors.password" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.password[0] }}
                </span>
                <div 
                    v-if="isPasswordFocused && !allRequirementsMet && formData.password"
                    class="absolute top-[100%] flex flex-col gap-2 border bg-white rounded-md p-4 text-sm"
                >
                    <div
                        class="text-smicos-secondary"
                        v-for="requirement in passwordRequirements"
                        :key="requirement.id"
                        :class="requirement.condition ? `hidden`: ``"
                    >
                        {{ requirement.description }}
                    </div>
                </div>
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Confirm password
                </span>
                <div class="flex items-center">
                    <div class="relative flex items-center w-full">
                        <input
                            v-model="formData.password_confirmation"
                            :type="showConfirmPassword ? `text` : `password`"
                            placeholder="Confirm password"
                            class="border focus:outline-smicos-primary p-2 rounded-md w-full"
                            :class="errors.confirm_password ? `ring-1 ring-smicos-secondary border-none` : `border`"
                            @focus="isConfirmPasswordFocused = true"
                            @blur="isConfirmPasswordFocused = false"
                        >
                        <button
                            @click="showConfirmPassword = !showConfirmPassword"
                            class="absolute text-gray-600 right-2"
                        >
                            <EyeOpenIcon
                                v-if="!showConfirmPassword"
                                class="w-4 h-4"
                            />
                            <EyeClosedIcon v-else class="w-4 h-4" />
                        </button>
                    </div>
                </div>
                <span
                    v-if="errors.confirm_password" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.confirm_password[0] }}
                </span>
                <div 
                    v-if="isConfirmPasswordFocused && formData.password_confirmation && formData.password_confirmation !== formData.password"
                    class="absolute top-[100%] flex flex-col gap-2 border text-smicos-secondary bg-[#FFF] rounded-md p-4 text-sm"
                >
                    Confirm password does not match.
                </div>
            </div>
        </div>
        <div class="flex items-start gap-3 mt-8">
            <input 
                @change="isAgree = !isAgree"
                type="checkbox" 
                class="cursor-pointer mt-1" 
                :checked="isAgree"
            >
            <span 
                @click="isAgree = !isAgree"
                class="cursor-pointer text-sm"
            >
                I have read and accepted the <a href="/terms-condition-privacy-policy" class="text-smicos-primary hover:text-smicos-primary/70 hover:underline" target="_blank">Terms and Conditions & Privacy Policy</a> | <a href="/end-user-license-agreement" class="text-smicos-primary hover:text-smicos-primary/70 hover:underline" target="_blank">End User License Agreement</a> by Smicos.com
            </span>
        </div>
        <div class="flex items-center gap-6">
            <button 
                @click="handleCreate"
                class="bg-smicos-primary hover:bg-smicos-primary/80 rounded-full px-6 py-2 text-white disabled:bg-gray-500"
                :disabled="!isSignUpEnabled"
            >
                Sign Up
            </button>
            <button 
                @click="emits('show-login-form')"
                class="text-smicos-primary hover:underline hover:underline-offset-2 hover:text-smicos-primary/80 font-semibold"
            >
                Login
            </button>
        </div>
    </div>
</template>