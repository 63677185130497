<script setup>
import { ref } from "vue";
import CustomModal from "@components/includes/CustomModal.vue";

const emits = defineEmits(['close'])

const isIframeLoaded = ref(false);
</script>

<template>
    <CustomModal
        @close="emits('close')"
        bodyCss="max-w-6xl"
    >
        <div class="p-4 text-lg text-center">
            <div
                v-if="!isIframeLoaded"
                @click.stop=""
                class="fixed top-0 left-0 w-full h-screen select-none"
                :class="{
                    'cursor-progress': !isIframeLoaded,
                }"
                style="z-index: 9997"
            >
                <div class="w-full h-1 bg-gray-200">
                    <div class="h-1 bg-smicos-primary animate-loading"></div>
                </div>
            </div>
            <div v-if="!isIframeLoaded" class="font-semibold text-[20px] text-smicos-primary p-4">
                Please wait...
            </div>
            <iframe 
                v-show="isIframeLoaded"
                src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ30323bXQnDeftwc80zFr2XYD65JjgfzP9u0oyCqzWp1awv8QCuTZw264Kb6zXBHRPQIuksg-BD?gv=true"
                style="border: 0"
                width="100%"
                height="600"
                frameborder="0"
                @load="isIframeLoaded = true"
            >
            </iframe>
        </div>

        <template #footer>
            <slot name="footer">
                <div></div>
                <!-- <div
                    class="flex items-center justify-end gap-2 p-6 space-x-2 font-sans border-t border-gray-200 rounded-b"
                >
                    <button
                        @click="emits('close')"
                        class="border hover:bg-slate-100 px-6 py-2 rounded-md"
                    >
                        Close
                    </button>
                </div> -->
            </slot>
        </template>
    </CustomModal>
</template>

<style scoped>
@keyframes loading {
    0% {
        width: 0%;
    }

    100% {
        width: 100%;
    }
}

.animate-loading {
    animation: loading 2.5s infinite ease-in-out;
}
</style>
