<script setup>
import {
    Quote2Icon,
    PlayIcon
} from "@components/icons";

// Utils
const parseTextContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html').body.textContent || "";

    const sentences = doc.split('. ');
    const firstSentence = `${sentences[0]}.`;

    if (firstSentence.length > 150) {
      return firstSentence.substring(0, 150) + '...';
    }

    return firstSentence;
}

const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

const props = defineProps({
    item: Object,
    urlRoute: String,
    authRequire: Boolean,
    cardCss: { default: "min-w-[30rem] max-w-[30rem]" },
    imageCss: { default: "h-[18rem]" },
    buttonText: { default: "Read more" },
    buttonCss: { default: "bg-[#E1251B] hover:bg-[#E1251B]/80 px-8 py-4" },
});

const emits = defineEmits(['scrollToLogin'])
</script>

<template>
    <div :class="[cardCss, `relative flex flex-col items-center`]">
        <div :class="[imageCss, `relative md:-bottom-10 -bottom-14 rounded-t-3xl border-[1px] border-[#00AEEE] w-full`]">
            <img :src="`${item.banner}`" alt="card banner" class="h-full w-full rounded-xl object-cover">
        </div>
        <div class="relative w-full max-h-[20.5rem]">
            <Quote2Icon class="w-full h-full" />
            <div class="absolute top-0 left-0 flex flex-col justify-start gap-4 md:!p-6 p-4 max-sm:text-sm h-[16rem]">
                <span class="text-[20px] font-bold leading-tight">
                    {{ item.title }}
                </span>
                <span>
                    {{ parseTextContent(item.overview) }}
                </span>
            </div>
            <div class="relative md:-top-[4.5rem] -top-[2.5rem] flex items-center justify-between md:h-[4.5rem] h-[2.5rem] md:px-6 px-[1rem]">
                <div class="text-[#77777A]">
                    {{ formatDate(item.published_date) }}
                </div>
                <div
                    v-if="authRequire"
                    @click="emits('scrollToLogin')"
                    :class="[buttonCss, `absolute bottom-0 right-0 flex items-center gap-2 rounded-full cursor-pointer`]"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold uppercase">
                        {{ buttonText }}
                    </span>
                    <PlayIcon class="w-5"/>
                </div>
                <a
                    v-if="!authRequire"
                    :href="urlRoute"
                    :class="[buttonCss, `absolute bottom-0 right-0 flex item-center gap-2 rounded-full cursor-pointer`]"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold uppercase">
                        {{ buttonText }}
                    </span>
                    <PlayIcon class="w-5"/>
                </a>
            </div>
        </div>
    </div>
</template>