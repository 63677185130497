<script setup>
import { ref, computed, watch, onMounted } from "vue";
import {
    CursorIcon,
    GraphIcon,
    IntegrationIcon,
    ScaleIcon,
    BubbleIcon,
    QuoteIcon,
    Quote2Icon,
    ArrowUpRightIcon
} from "../icons";

// Swiper
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Lottie Animation
import { LottieAnimation } from "lottie-web-vue";
import mobileAnimation from "/public/images/homepage/lottie/phone.json";

// Particle JS
import { loadSlim } from "tsparticles-slim";

const listDescription = ref({});
const listPackage = ref("");

const showTooltip = (list, item) => {
    listDescription.value = list;
    listPackage.value = item;
};

const hideTooltip = () => {
    listDescription.value = "";
};

const youtubeIframeLoaded = ref(false);
const youtubeIframeSrc = ref("");

const particlesInit = async engine => {
    await loadSlim(engine);
};

// Blogs
const blogs = ref([]);

const getBlogs = async () => {
    try {
        const res = await window.axios(`/blogs/api/all`);
        blogs.value = res.data.slice(0, 3);
    } catch (error) {
        console.error(error);
    }
}

getBlogs();

const parseTextContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html').body.textContent || "";

    const sentences = doc.split('. ');
    const firstSentence = `${sentences[0]}.`;

    if (firstSentence.length > 150) {
      return firstSentence.substring(0, 150) + '...';
    }

    return firstSentence;
}

const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

const solutions = [
    { title: "Easy User Interface", icon: CursorIcon },
    { title: "Smart Analytics", icon: GraphIcon },
    { title: "Smart Integrations", icon: IntegrationIcon },
    { title: "Scalable Solutions", icon: ScaleIcon },
    { title: "Intelligent Response", icon: BubbleIcon },
]

const free = [
    {id: 1, name: "2 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "1,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "500 MB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 10, name: "Website and Facebook Integration", description: "Smicos Easy Integration to Website and Facebook Messenger"},
    {id: 11, name: "2 Roles (Admin and Sales)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin and Sales)"},
    {id: 12, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 13, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 14, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const starter = [
    {id: 1, name: "3 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "5,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "1 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 11, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 12, name: "Starter-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data"},
    {id: 13, name: "3 Roles (Admin, Sales, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, and Live Agent)"},
    {id: 14, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 15, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 16, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const pro = [
    {id: 1, name: "5 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "10,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "3 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 11, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 12, name: "Pro-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data"},
    {id: 13, name: "5 Roles (Admin, Sales, Marketing, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, Marketing and 2 Live Agent)"},
    {id: 14, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 15, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 16, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const advanced = [
    {id: 1, name: "10 Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "100,000 Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "5 GB Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Training Feature", description: "Feature that allows you to enhance your Smicos bot’s understanding, responses, and overall performance. (Includes: Threads and Train Story)"},
    {id: 11, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 12, name: "Website, Facebook, and Instagram Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, and Instagram"},
    {id: 13, name: "Advance-Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data (Includes: All Reports)"},
    {id: 14, name: "7 Roles (Admin, Sales, Marketing, and Live Agents)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot. (Roles Available: Admin, Sales, Marketing and 4 Live Agents)"},
    {id: 15, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 16, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 17, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const enterprise = [
    {id: 1, name: "Unlimited Active Smicos Bots", description: "An active Smicos bot that is connected to any integration"},
    {id: 2, name: "Unlimited Valid Chats", description: "A valid chat is one interaction by the Smicos bot. This excludes the Test Bot and Live Agent chats"},
    {id: 3, name: "Unlimited Storage", description: "Storage for the assets of your chatbot"},
    {id: 4, name: "Unlimited Story Flow", description: "The number of created stories in the Smicos Visual Flow Builder"},
    {id: 5, name: "Easy Chat Builder", description: "The Smicos Visual Flow Builder is used for easy creation of conversations"},
    {id: 6, name: "Smicos Test Bot", description: "The Smicos Test Bot is used to test your bot’s performance and functionality"},
    {id: 7, name: "Customer Database", description: "It is the memory bank that helps your chatbot remember details, preferences, and interactions with each user"},
    {id: 8, name: "Chat Archive", description: "Each chat is timestamped and preserved, creating a comprehensive record of the interactions between your chatbot and users"},
    {id: 9, name: "Transfer to Live Agent", description: "Ensures that users have the option for a real-time conversation with a knowledgeable human representative"},
    {id: 10, name: "Training Feature", description: "Feature that allows you to enhance your Smicos bot’s understanding, responses, and overall performance. (Includes: Threads and Train Story)"},
    {id: 11, name: "Pre-made Templates", description: "Readily available templates for Lead Generation, Customer Service, and Hiring"},
    {id: 12, name: "Multiple Platform Integration", description: "Smicos Easy Integration to Website, Facebook Messenger, Instagram, WhatsApp, and Viber"},
    {id: 13, name: "Full Analytics and Data Reports", description: "Measures Smicos bot performance using real-time analytics and data (Includes: All Reports)"},
    {id: 14, name: "Unlimited Roles (Admin, Sales, Marketing, and Live Agent)", description: "Roles define the privileges and access levels for different individuals or groups interacting with your chatbot"},
    {id: 15, name: "Smicos Support", description: "A dedicated feature that ensures users receive timely help, answers to queries, and a guide whenever you have any concerns as a User"},
    {id: 16, name: "Dedicated Account Manager", description: "A dedicated account manager assigned to serve as a direct point of contact between you and the Smicos Team"},
    {id: 17, name: "Dedicated Technical Support", description: "A dedicated technical support assigned to resolve technical issues, provide guidance, and ensure your Smicos bot operates seamlessly"},
    {id: 18, name: "Data Security", description: "Measures and practices designed to protect digital information from unauthorized access, corruption, or theft. It includes encryption, access controls, and security protocols to ensure data confidentiality, integrity, and availability."},
    {id: 19, name: "Option for Add-ons", description: "A menu of additional features, services, or capabilities that you can selectively integrate into your Smicos bot, providing a customized and scalable solution"}
]

const options = computed(() => {
    return [
    {
        fpsLimit: 120,
        fullScreen: {
            enable: false
        },
        particles: {
            color: {
                value: '#ffffff'
            },
            links: {
                color: '#ffffff',
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1
            },
            collisions: {
                enable: true
            },
            move: {
                direction: 'none',
                enable: true,
                outModes: {
                    default: 'bounce'
                },
                random: false,
                speed: 1,
                straight: false
            },
            number: {
                density: {
                    enable: true,
                    area: 800
                },
                value: 100
            },
            opacity: {
                value: 0.5
            },
            shape: {
                type: 'circle'
            },
            size: {
                value: { min: 1, max: 5 },
            }
        },
        detectRetina: true
    }]
});

const banners = [
    { 
        id: 1, 
        file: "carousel-bg.webp", 
        title: "Intelligent Conversations Amplified:", 
        titleStyle: "bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent", 
        description: "Discover the Smart Integrated AI Chatbot for Business", 
        descriptionStyle: "text-[#1E1E1E]",
        actionText: "Harness the power of Data and Artificial Intelligence to enhance the customer experience, provide personalized assistance and streamline various business operations",
        actionTextStyle: "text-[#8F8F8F]",
        actionImage: null, 
        buttonImage: "banner-btn4.png"
    },
    { 
        id: 2, 
        file: "homepage-banner-5.png", 
        title: "", 
        titleStyle: "text-[#FFF]", 
        description: "", 
        descriptionStyle: "text-[#FFF]",
        actionText: null,
        actionTextStyle: null,
        actionImage: null, 
        buttonImage: "banner-btn5.png"
    },
    // { 
    //     id: 2, 
    //     file: "homepage-banner-1.png", 
    //     title: "Increase sales with good customer service.", 
    //     titleStyle: "text-[#FFF]", 
    //     description: "Brew better customer relationships with a Chatbot!", 
    //     descriptionStyle: "text-[#FFF]",
    //     actionText: null,
    //     actionTextStyle: null,
    //     actionImage: "cta-1.png", 
    //     buttonImage: "banner-btn3.png"
    // },
    // { 
    //     id: 3, 
    //     file: "homepage-banner-2.png", 
    //     title: "Drive more sales with good customer management.", 
    //     titleStyle: "text-[#FFF]", 
    //     description: "That's why all business need better Chatbot!", 
    //     descriptionStyle: "text-[#FFF]",
    //     actionText: null,
    //     actionTextStyle: null,
    //     actionImage: "cta-1.png", 
    //     buttonImage: "banner-btn3.png"
    // },
    // { 
    //     id: 4, 
    //     file: "homepage-banner-3.png", 
    //     title: "Build higher sales with good customer management.", 
    //     titleStyle: "text-[#FFF]", 
    //     description: "Learn how an AI chatbot can be the foundation of an effective business.", 
    //     descriptionStyle: "text-[#FFF]",
    //     actionText: null,
    //     actionTextStyle: null,
    //     actionImage: "cta-1.png", 
    //     buttonImage: "banner-btn3.png"
    // },
]

const clients = [
    { id: 1, name: "panasonic", image: "panasonic.webp", size:"xl:h-[8rem] h-[6rem] xl:w-[12rem] w-[10rem]" },
    // { id: 2, name: "ovaltine", image: "ovaltine.webp", size:"xl:w-[10rem] w-[8rem]" },
    { id: 2, name: "perla", image: "perla.webp", size:"xl:w-[10rem] w-[8rem]" },
    { id: 3, name: "perla-pure", image: "perla-pure.png", size:"xl:w-[10rem] w-[8rem]" },
    { id: 4, name: "pinoybuilders", image: "pinoybuilders.png", size:"xl:w-[12rem] w-[9.5rem]"  },
    { id: 5, name: "tricom", image: "tricom.webp", size:"xl:w-[12rem] w-[9.5rem]"  },
    { id: 6, name: "purplebug", image: "purplebug.webp", size:"xl:w-[15.5rem] w-[12rem]" },
    { id: 7, name: "mydoctorfider", image: "mydoctorfinder.webp", size:"xl:w-[12rem] w-[10rem]" },
    { id: 4, name: "skinwhite", image: "skinwhite-logo.png", size:"xl:w-[12rem] w-[9.5rem]"  },
    { id: 5, name: "knauf", image: "knauf-logo.png", size:"xl:w-[12rem] w-[9.5rem]"  },
    { id: 6, name: "extract", image: "extract-logo.png", size:"xl:w-[15.5rem] w-[12rem]" },
    { id: 7, name: "eton", image: "eton-logo.png", size:"xl:w-[12rem] w-[10rem]" },
]

// Swiper functions
const modules = ref([Autoplay]);

const clientsSwiperPagination = computed(() => {
    if(window.innerWidth < 768) return 1;
    return 5;
})

const slidePagination = computed(() => {
    if(window.innerWidth < 1920) return 1;
    return 3;
});

const bannerZIndex = ref(48);

// Announcement popup modal
const showPopup = computed(() => {
    const popupClosed = localStorage.getItem("isShowPopup");
    switch (popupClosed) {
        case "true":
            return true;
        case "false":
            return false;
        default:
            return true;
    }
});

// Meta pixel
const redirectSubscribeNow = () => {
    fbq('track', 'ClickSubscribeNow');
    window.location.href = 'https://app.smicos.com/register';
}

const redirectContactUs = () => {
    fbq('track', 'ClickContactUs');
    window.location.href = 'https://smicos.com/contact-us';
}

onMounted(() => {
    // Onscroll animation
    window.onload = () => {
        setTimeout(() => {
            // handPosition.value = 30;
            const leftHand = document.querySelector('.left-hand');
            const rightHand = document.querySelector('.right-hand');
            leftHand.style.transform = 'translateX(-55%)';
            rightHand.style.transform = 'translateX(58%)';
        }, 500);

        setTimeout(() => {
            bannerZIndex.value = 50;
        }, 1000);
    }

    // Lazy load YouTube iframe
    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
        youtubeIframeSrc.value = "https://www.youtube-nocookie.com/embed/videoseries?si=e9zTzXSXMhLJ7xb_&amp;list=PLJTADOU03T0owxkosHXtElV0WF68sKnGz";
        youtubeIframeLoaded.value = true;
        observer.disconnect();
        }
    });

    observer.observe(document.querySelector(".yt-video"));
});
</script>

<template>
    <div class="relative flex flex-col pt-0 md:pt-[100px]">
        <!-- Banner Hands -->
        <div
            class="xl:block hidden absolute w-full md:h-[900px] h-[600px] pt-[7.5rem]"
        >
            <div class="relative flex">
                <img
                    class="left-hand absolute ease-in-out duration-1000 w-[55%] max-w-[55%] left-0 z-[49]"
                    :src="`/images/homepage/elements/hand-1.webp`"
                    alt="hand logo"
                >
                <img
                    class="right-hand absolute top-[25rem] ease-in-out duration-1000 w-[55%] max-w-[55%] right-0 z-[49]"
                    :src="`/images/homepage/elements/hand-2.webp`"
                    alt="hand logo"
                >
            </div>
        </div>

        <!-- Home Banner -->
        <swiper
            :slides-per-view="1"
            :autoplay="{
                delay: 5000,
                disableOnInteraction: false,
            }"
            :loop="true"
            :pagination="true"
            :modules="modules"
            class="w-full md:pt-[7rem]"
        >
            <swiper-slide v-for="banner in banners" :key="banner.id">
                <div 
                    :style="`background: url('images/homepage/${banner.file}') no-repeat; background-size: cover; background-position: center;`" 
                    class="relative flex items-center justify-center gap-6 bg-cover w-full 2xl:h-[900px] lg:h-[750px] h-[600px]"
                >
                    <div class="flex flex-col items-center justify-center md:text-center md:gap-10 gap-4 2xl:max-w-[60rem] max-w-[45rem] max-sm:p-6">
                        <div :class="[banner.titleStyle, `font-extrabold 2xl:text-[50px] lg:text-[40px] text-[35px]`]">
                            {{ banner.title }}
                        </div>
                        <h1 :class="[banner.descriptionStyle, `font-bold 2xl:text-[35px] lg:text-[29px] text-[22px] md:px-14`]">
                            {{ banner.description }}
                        </h1>
                        <div 
                            v-if="banner.actionText"
                            :class="[banner.actionTextStyle, `2xl:text-[26px] lg:text-[20px] text-[18px]`]"
                        >
                            {{ banner.actionText }}
                        </div>
                        <img 
                            v-if="banner.actionImage"
                            :src="`images/homepage/${banner.actionImage}`"
                            class="max-sm:py-4"
                        >
                        <div 
                            v-if="banner.buttonImage"
                            class="flex justify-center"
                            :class="[banner.id === 2 && `mt-[18rem] w-[70%] md:ml-20`]"
                        >
                            <a href="https://app.smicos.com/register" target="_blank">
                                <img
                                    :src="`images/homepage/${banner.buttonImage}`"
                                    alt="banner button"
                                >
                            </a>
                        </div>
                        <!-- <a 
                            v-if="banner.id === 1"
                            href="https://app.smicos.com/register" 
                            class="flex justify-center" 
                            :class="`relative z-[${bannerZIndex}]`" target="_blank"
                        >
                            <span
                                class="bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-8 py-4 text-[18px] text-white font-semibold cursor-pointer"
                                :class="`relative z-[${bannerZIndex}]`"
                            >
                                Get Smicos for <span class="font-bold">FREE</span>
                            </span>
                        </a>  -->
                    </div>
                </div>
            </swiper-slide>
        </swiper>

        <!-- Exceptional Customer Section -->
        <div class="slide-right relative flex flex-col items-center justify-center md:gap-4 md:gap-10 gap-0 px-8 md:!py-14 py-8 w-full bg-gradient-to-r from-[#F2CD59] to-[#E37C0D]">
            <span class="text-white md:text-[24px] text-[18px] uppercase text-center">
                Fostering better conversations for exceptional customer experiences.
            </span>
            <div class="w-full md:px-20 px-0">
                <swiper
                    :slides-per-view="clientsSwiperPagination"
                    :autoplay="{
                        delay: 5000,
                        disableOnInteraction: false,
                    }"
                    :loop="true"
                    :pagination="true"
                    :modules="modules"
                    class="w-full"
                >
                    <swiper-slide v-for="client in clients" :key="client.id">
                        <div class="flex items-center justify-center max-h-[15rem] h-[15rem] w-full">
                            <img :src="`/images/homepage/sections/${client.image}`" :class="`${client.size}`" :alt="`${client.name} logo`">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <!-- Solutions Section -->
        <div class="slide-left relative flex md:flex-row flex-col items-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-8">
            <div class="absolute top-0 left-0 md:w-[50rem] w-full h-full">
                <vue-particles
                    id="tsparticles"
                    :particlesInit="particlesInit"
                    :options="options"
                    style="height: 100%;"
                />
            </div>
            <div class="md:hidden block flex-1">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    style="height: 40rem;"
                />
            </div>
            <div class="flex-1 flex flex-col gap-10 2xl:pl-20 pl-10">
                <h2 class="text-white font-bold text-[35px]">
                    Introducing our smart chatbot solutions for your business!
                </h2>
                <span class="text-white text-[20px]">
                    Smicos (Smart Integrated Communication Solutions) is an all-in-one platform for small businesses to enterprises, offering EASY TO USE and SMART chatbot solutions. It harnesses the power of artificial intelligence and natural language processing (NLP) to understand and respond to customer queries and requests, simulating human conversation.
                </span>
                <div class="flex flex-wrap gap-14 items-center">
                    <div
                        v-for="solution in solutions"
                        class="flex items-center gap-1 2xl:w-[40%] w-full"
                    >
                        <component :is="solution.icon" class="2xl:w-7 w-10"/>
                        <div class="flex items-center">
                            <div class="w-5 h-5 bg-[#E37C0D] rounded-full solutions-bullet"></div>
                            <span class="relative -left-2 text-white font-bold">
                                {{ solution.title }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="md:block hidden flex-1 p-10">
                <LottieAnimation
                    :animation-data="mobileAnimation"
                    :auto-play="true"
                    :loop="true"
                    :speed="1"
                    style="height: 40rem;"
                />
            </div>
        </div>

        <!-- Pricing Section -->
        <div class="relative flex items-center justify-center md:min-h-[600px] min-h-[400px] bg-red-40">
            <img :src="`/images/homepage/elements/element-2.webp`" class="absolute -top-40 z-10" alt="element image">
            <div class="flex flex-col z-20 justify-center items-center md:gap-10 p-8 lg:text-left text-center">
            <h2 class="font-extrabold md:text-[50px] text-[35px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                Discover data-driven AI-enabled chatbot solutions.
            </h2>
            <div v-if="youtubeIframeLoaded" class="relative md:top-2 yt-video slide-right">
                <iframe
                    class="w-full h-full rounded-xl"
                    :src="youtubeIframeSrc"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
            <div v-else class="relative md:top-2 yt-video slide-right">
                <div class="w-full h-[20rem] bg-gray-300 rounded-md flex items-center justify-center">
                Loading YouTube video...
                </div>
            </div>
            </div>
        </div>
        <div class="slide-left flex flex-col items-center justify-center md:gap-2 gap-4 p-8">
            <span class="md:text-[50px] text-[35px] font-extrabold text-transparent bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text">
                Compare Chatbot Features
            </span>
            <span class="font-bold text-[#1E1E1E] md:text-[25px] text-[22px]">
                Choose the offer that suits your business needs.
            </span>
            <!-- Price list -->
            <div class="flex flex-wrap items-start justify-center gap-10 w-full md:mt-10">
                <!-- Packages -->
                <div class="flex flex-col gap-6">
                    <div class="flex 2xl:flex-nowrap flex-wrap gap-10">
                        <!-- Trial -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Free
                            </span>
                            <div
                                class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] h-[60rem]"
                            >
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            0
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in free"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `free`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59]">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `free`"
                                                class="absolute bottom-[100%] left-[100%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] max-w-[20rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                @click="redirectSubscribeNow"
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 w-full cursor-pointer" 
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </div>
                        </div>
                        <!-- Starter -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Starter
                            </span>
                            <div
                                class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] h-[60rem]"
                            >
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            19
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Small Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in starter"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `starter`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59]">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `starter`"
                                                class="absolute bottom-[100%] left-[100%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] max-w-[20rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div    
                                @click="redirectSubscribeNow"
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 w-full cursor-pointer"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </div>
                        </div>
                        <!-- Pro -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Pro
                            </span>
                            <div class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] h-[60rem]">
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            79
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Small to Medium Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in pro"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `pro`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59]">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `pro`"
                                                class="absolute bottom-[100%] left-[100%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] max-w-[20rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                @click="redirectSubscribeNow"
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 w-full cursor-pointer"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </div>
                        </div>
                        <!-- Advanced -->
                        <div class="flex flex-col items-center gap-4">
                            <span class="text-[#E1251B] text-[40px] font-extrabold">
                                Advanced
                            </span>
                            <div class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] h-[60rem]">
                                <div class="flex items-end text-[#E1251B] leading-none">
                                    <div class="flex items-start">
                                        <span class="text-[30px] font-bold">
                                            $
                                        </span>
                                        <span class="text-[60px] font-extrabold">
                                            299
                                        </span>
                                    </div>
                                    <span class="text-[25px] font-bold">
                                        /mo
                                    </span>
                                </div>
                                <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                    Medium to Large Companies
                                </div>
                                <div class="flex flex-col gap-2">
                                    <div
                                        v-for="list in advanced"
                                        class="flex gap-2 cursor-default"
                                        @mouseenter="showTooltip(list, `advanced`)"
                                        @mouseleave="hideTooltip"
                                    >
                                        <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                            <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                        <div class="relative hover:text-[#F2CD59]">
                                            <span>
                                                {{ list.name }}
                                            </span>
                                            <div
                                                v-if="listDescription.id && listPackage === `advanced`"
                                                class="absolute bottom-[100%] left-[100%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] max-w-[20rem] text-left"
                                                :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                            >
                                                {{ list.description }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div 
                                @click="redirectSubscribeNow"
                                class="2xl:hidden flex items-center justify-center gap-2 bg-[#E1251B] rounded-full px-6 py-2 w-full cursor-pointer"
                            >
                                <div class="text-[16px] text-white text-center font-semibold">
                                    Subscribe Now
                                </div>
                                <ArrowUpRightIcon class="w-5 text-white"/>
                            </div>
                        </div>
                    </div>
                    <!-- Trial button -->
                    <div class="2xl:block hidden w-full px-6">
                        <div
                            @click="redirectSubscribeNow"
                            class="flex items-center justify-center gap-2 bg-[#E1251B] hover:bg-[#E1251B]/70 rounded-full px-6 py-2 cursor-pointer"
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Subscribe Now
                            </div>
                            <ArrowUpRightIcon class="w-5 text-white"/>
                        </div>
                    </div>
                </div>
                <!-- Enterprise -->
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col items-center gap-4">
                        <span class="text-[#E1251B] text-[40px] font-extrabold">
                            Enterprise
                        </span>
                        <div
                            class="group flex flex-col items-center justify-start gap-6 border-[1px] border-[#E1251B] hover:bg-gradient-to-b hover:from-[#01ADED] hover:to-[#384590] hover:border-[#00AEEE] hover:text-white rounded-3xl p-8 w-[20rem] h-[60rem]"
                        >
                            <div class="border-[1px] border-[#00AEEE] group-hover:border-[#F2CD59] group-hover:text-[#F2CD59] rounded-full py-1 text-[#00AEEE] font-semibold text-center w-[16rem]">
                                Medium to Large Companies
                            </div>
                            <div class="flex flex-col gap-2">
                                <div
                                    v-for="list in enterprise"
                                    class="flex gap-2 cursor-default"
                                    @mouseenter="showTooltip(list, `enterprise`)"
                                    @mouseleave="hideTooltip"
                                >
                                    <div class="text-[#E1251B] group-hover:text-[#F2CD59]">
                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.79004 15.7639L11.9439 21.875L25.79 8.125" stroke="currentColor" stroke-width="5" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div class="relative hover:text-[#F2CD59]">
                                        <span>
                                            {{ list.name }}
                                        </span>
                                        <div
                                            v-if="listDescription.id && listPackage === `enterprise`"
                                            class="absolute bottom-[100%] right-[100%] z-[100] p-3 shadow-lg border-[1px] border-[#E1251B] rounded-md text-sm bg-white w-[20rem] max-w-[20rem] text-left"
                                            :class="[list.id !== listDescription.id ? `hidden` : ``]"
                                        >
                                            {{ list.description }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div 
                            @click="redirectContactUs"
                            class="2xl:hidden flex items-center justify-center gap-2 bg-[#00AEEE] hover:bg-[#00AEEE]/70 rounded-full px-6 py-2 w-full cursor-pointer" 
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Contact Sales
                            </div>
                        </div>
                    </div>
                    <div class="2xl:block hidden w-full px-6">
                        <div 
                            @click="redirectContactUs"
                            class="flex items-center justify-center gap-2 bg-[#00AEEE] hover:bg-[#00AEEE]/70 rounded-full px-6 py-2 cursor-pointer" 
                        >
                            <div class="text-[16px] text-white text-center font-semibold">
                                Contact Sales
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Blogs Preview Section -->
        <div class="slide-right flex flex-col items-center bg-gradient-to-r from-[#384590] to-[#01ADED] 2xl:!py-20 2xl:mt-[10rem] md:p-10 p-4">
            <span class="text-[#FFF] text-[24px] uppercase">
                Stay ahead by immersing yourself in our informative articles
            </span>
            <!-- Blogs -->
            <div
                v-if="blogs.length > 0"
                class="w-full"
            >
                <swiper
                    :slidesPerView="slidePagination"
                    :spaceBetween="30"
                    :pagination="{
                        dynamicBullets: true,
                    }"
                    :autoplay="{
                        delay: 5000,
                        disableOnInteraction: false,
                    }"
                    :loop="true"
                    :modules="modules"
                    class="relative w-full"
                >
                    <swiper-slide v-for="blog in blogs" :key="blog.id">
                        <div class="relative flex flex-col items-center">
                            <div class="relative md:-bottom-10 -bottom-14 rounded-t-3xl border-[1px] border-[#00AEEE] md:w-[29.5rem] w-full h-[18rem]">
                                <img :src="`${blog.banner}`" alt="card banner" class="h-full w-full rounded-xl object-cover">
                            </div>
                            <div class="relative flex">
                                <Quote2Icon class="w-full h-full" />
                                <div class="absolute top-0 left-0 flex flex-col justify-start gap-4 md:!p-8 p-10 max-sm:text-sm h-full w-full">
                                    <span class="text-[20px] font-bold">
                                        {{ blog.title }}
                                    </span>
                                    <span>
                                        {{ parseTextContent(blog.content) }}
                                    </span>
                                </div>
                            </div>
                            <div class="relative md:-top-[4.5rem] -top-[3rem] flex items-center justify-between md:w-[29.5rem] w-full md:h-[4.5rem] h-[2.5rem] md:px-4 px-6">
                                <div class="text-[#77777A] pl-4">
                                    {{ formatDate(blog.published_date) }}
                                </div>
                                <a
                                    :href="`/blogs/${blog.slug}`"
                                    class="flex gap-2 bg-[#E1251B] hover:bg-[#E1251B]/80 rounded-full md:!px-8 px-4 md:!py-4 py-2 cursor-pointer"
                                >
                                    <span class="text-[#FFF] max-sm:text-sm font-bold max-sm:p-2">
                                        Read More
                                    </span>
                                    <ArrowUpRightIcon class="w-5"/>
                                </a>
                            </div>
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
            <div v-else class="text-white font-semibold text-[20px] py-8">
                No articles posted
            </div>
        </div>
    </div>
</template>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

.bug{
    border: 1px solid red;
}

.solutions-bullet{
    box-shadow: 0 0 10px 5px rgb(227, 124, 13, 1);
}

.left-30{
    left: -30%;
}

.right-30{
    right: -30%;
}
</style>
