<script setup>
import { ref, computed, watch } from "vue";
import { useAuthStore } from "@/stores/Auth";
import {
    ArrowUpRightIcon
} from "@components/icons";

import AcademyCard from "@components/academy/AcademyCard.vue";
import Card from "@components/includes/Card.vue";
// import AcademyRegistration from "@components/academy/AcademyRegistration.vue";
// import AcademyLogin from "@components/academy/AcademyLogin.vue";

const storeAuth = useAuthStore();

const todayDate = moment().startOf().format("YYYY-MM-DD HH:mm:ss");

const trainings = ref([]);
const getTrainings = async () => {
    try {
        // const res = await window.axios(`/api/academies`);
        const res = await window.axios(`https://app.smicos.com/api/website/sanctum/website-trainings`);
        trainings.value = res.data;
    } catch (error) {
        console.error(error);
    }
}

getTrainings();

const filteredAcademy = computed(() => {
    let items = trainings.value;
    if(items.length < 1) return { current: [], others: [] };

    // const others = [
    //     "Transforming Digital Marketing with AI",
    //     "Introduction to AI in Digital Marketing",
    //     "Enhancing Customer Management with AI Chatbots",
    //     "Inside Smicos: Visual Storyflow Builder",
    //     "Practical Guide to Using AI Chatbots"        
    // ];

    let fundamentalTrainings = items.filter(item => item.published_date > todayDate)
        .sort((a, b) => a.published_date.localeCompare(b.published_date))
        .slice(0, 4);

    let otherTrainings = items.sort((a, b) => b.published_date.localeCompare(a.published_date))
        .slice(0, 4);

    return {
        current: fundamentalTrainings,
        others: otherTrainings
    };
});
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center p-4">
                <div class="md:text-[60px] text-[40px] leading-tight font-extrabold">
                    Unlock your potential with <span class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">Smicos Academy</span>
                </div>
                <h1 class="text-[#8F8F8F] md:text-[24px] text-[20px]">
                    Join our Smicos Academy trainings and get certified in AI-powered customer management skills.
                </h1>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>
        <!-- advertising -->
        <div class="flex flex-col bg-gradient-to-r from-[#0A9EDF] to-[#31549D] md:p-20 p-8 z-[2]">
            <div class="flex md:flex-row flex-col items-center justify-between gap-10">
                <div class="flex flex-col item-center justify-center gap-2 text-center w-full">
                    <h1 class="md:text-[40px] text-[30px] text-white font-extrabold text-center leading-tight">
                        Transformative Customer Management Solutions:
                    </h1>
                    <h1 class="md:text-[40px] text-[30px] bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent font-extrabold text-center leading-tight">
                        Learn To Utilize AI-Powered Features
                    </h1>
                </div>
                <!-- <a
                    href="/academy/view"
                    class="flex gap-2 rounded-full cursor-pointer bg-smicos-primary hover:bg-smicos-primary/80 px-8 py-2"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold max-sm:p-2 uppercase">
                        View more
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a> -->
            </div>
            <div class="flex flex-wrap justify-between items-center">
                <div v-for="academy in filteredAcademy.current" :key="academy.id">
                    <AcademyCard 
                        cardCss="max-w-[25rem]"
                        buttonCss="bg-smicos-secondary hover:bg-smicos-secondary/80 md:px-10 px-[2rem] md:py-2 py-[0.5rem]"
                        buttonText="Join"
                        :item="academy"
                        :urlRoute="`/academy/${academy.slug}`"
                    />
                </div>
            </div>
        </div>

       <!-- track progress -->
        <!-- <div 
            v-if="!storeAuth.auth.id"
            ref="loginSection"
            class="flex md:flex-row flex-col justify-center gap-20 md:px-20 px-[1rem] md:py-[7.5rem] py-[1rem]"
        >
            <div class="flex md:flex-row flex-col items-start justify-between w-full md:gap-20 gap-10 md:max-w-[80%] max-sm:p-4">
                <div class="flex flex-col md:max-w-[25rem]">
                    <div class="font-extrabold leading-tight md:text-[45px] text-[40px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent max-sm:text-center">
                        Track Your Progress
                    </div>
                </div>
                <AcademyRegistration 
                    v-if="academyForm === `register`"
                    @click-login="handleAcademyForm"
                />
                <AcademyLogin 
                    v-if="academyForm === `login`"
                    @click-register="handleAcademyForm"
                />
            </div>
        </div> -->

        <!-- other courses -->
        <div class="flex flex-col bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] md:p-20 p-8 z-[2]">
            <div class="flex md:flex-row flex-col items-center justify-between gap-4">
                <h1 class="text-[40px] text-white font-extrabold">
                    Other Trainings
                </h1>
                <!-- <a
                    href="/academy"
                    class="flex gap-2 rounded-full cursor-pointer bg-smicos-secondary hover:bg-smicos-secondary/80 px-8 py-2"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold max-sm:p-2 uppercase">
                        View more
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a> -->
            </div>
            <div class="flex md:flex-row flex-col justify-between items-center">
                <div 
                    v-if="filteredAcademy.others.length > 0"
                    v-for="academy in filteredAcademy.others" 
                    :key="academy.id"
                >
                    <AcademyCard 
                        cardCss="max-w-[25rem]"
                        buttonCss="bg-smicos-secondary hover:bg-smicos-secondary/80 md:px-10 px-[2rem] md:py-2 py-[0.5rem]"
                        buttonText="Join"
                        :item="academy"
                        :urlRoute="`/academy/${academy.slug}`"
                    />
                </div>
                <span
                    v-else 
                    class="text-white font-bold text-[24px] w-full text-center py-10"
                >
                    No previous training
                </span>
            </div>
        </div>
    </div>
</template>