<script setup>
import { ref, reactive, computed, onMounted } from "vue";
import axios from 'axios'

import { useAuthStore } from "@/stores/Auth";
import {
    ArrowUpRightIcon,
    StarIcon,
    ClockIcon,
    CheckListIcon
} from "@components/icons";

import AcademyCard from "@components/academy/AcademyCard.vue";
import AcademyLogin from "@components/academy/AcademyLogin.vue";
import AcademyRegistration from "@components/academy/AcademyRegistration.vue";
import AcademyEmailVerify from "@components/academy/AcademyEmailVerify.vue";
import _ from "lodash";

const props = defineProps({slug: String});

const storeAuth = useAuthStore();

// Utils
const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

const progressSection = ref(null);
const scrollToLogin = () => {
    progressSection.value.scrollIntoView({ behavior: 'smooth' });
}

const academy = ref({});

const options = reactive({
    headers: {
        Authorization: ""
    }
});

const handleJoinWebinar = async () => {
    try {
        const res = await axios.post(`https://app.smicos.com/api/website/sanctum/website-trainings/${props.slug}/join`, {}, options);
        storeAuth.auth.websiteTrainings.push(res.data.id);
    } catch (error) {
        console.error(error);
    }
}

const isRegisterForm = ref(false);
const handleForm = () => {
    isRegisterForm.value = !isRegisterForm.value;
    scrollToLogin();
}


const hasJoined = computed(() => {
    return storeAuth.auth?.websiteTrainings.includes(academy.value.id);
});

const academies = ref([]);
const getAcademies = async () => {
    try {
        const res = await axios.get(`https://app.smicos.com/api/website/sanctum/website-trainings`);
        academies.value = res.data;
        academy.value = academies.value.find((a) => a.slug === props.slug);
    } catch (error) {
        console.error(error);
    }
}

getAcademies();

const filteredAcademy = computed(() => {
    let items = academies.value;
    if(items.length < 1) return [];

    items = items.slice(0, 4);

    return items;
});

(async () => {
    storeAuth.auth = JSON.parse(localStorage.getItem('user')) ?? {};
    // const queryString = window.location.search;
    // const urlParams = new URLSearchParams(queryString);
    // const hasVerified = urlParams.get('verified');

    
    if (storeAuth.auth.id) {
        options.headers.Authorization = `Bearer ${storeAuth.auth.api_token}`;

        const res = await axios('https://app.smicos.com/api/website/sanctum/user', options); 
        Object.assign(storeAuth.auth, res.data);
        localStorage.setItem('user', JSON.stringify(storeAuth.auth))
    }

    setTimeout(() => {
        scrollToLogin();
    }, 1500);
})();
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center">
                <div class="text-[60px] leading-tight font-extrabold">
                    Unlock your potential with <span class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">Smicos Academy</span>
                </div>
                <h1 class="text-[#8F8F8F] text-[24px]">
                    Join our Smicos Academy trainings and get certified in AI-powered customer management skills
                </h1>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>
        <!-- advertising -->
        <div class="flex justify-center bg-gradient-to-r from-[#0A9EDF] to-[#31549D] p-20 z-[2]">
            <div class="flex flex-col gap-8 max-w-[80%]">
                <span class="text-[40px] leading-tight font-extrabold text-white">
                    Who Can Benefit From This Training?
                </span>
                <div class="flex items-center justify-between w-full">
                    <div class="flex items-center gap-4">
                        <div
                            v-for="target in academy.targets"
                            class="rounded-full text-white px-6 py-2 bg-smicos-primary"
                        >
                            {{ target }}
                        </div>
                    </div>
                </div>
                <h1 class="text-[50px] leading-tight font-extrabold bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent">
                    {{ academy.title }}
                </h1>
                <div class="flex items-center gap-2">
                    <ClockIcon class="w-6 text-[#F2CD59]"/>
                    <span class="text-white font-semibold">
                        {{ formatDate(academy.published_date) }}
                    </span>
                </div>
                <div class="flex flex-col gap-2">
                    <span class="text-[30px] leading-tight font-extrabold bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] bg-clip-text text-transparent">
                        What This Webinar is All About:
                    </span>
                    <span class="text-white">
                        {{ academy.overview }}
                    </span>
                </div>
                <div class="flex flex-col gap-10">
                    <div
                        v-for="lesson in academy.lessons"
                        class="flex items-start gap-4 text-white"
                    >
                        <CheckListIcon class="w-8" />
                        <div class="flex flex-col gap-2">
                            <span class="font-bold text-xl mt-2">
                                {{ lesson.title }}
                            </span>
                            <span 
                                v-for="item in lesson.learnings"
                                class="text-sm"
                            >
                                {{ item }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- track progress -->
        <div 
            ref="progressSection"
            class="flex justify-center gap-20 px-20 py-[7.5rem]"
        >
            <div :class="[
                storeAuth.auth.email_verified_at ? `flex-col items-center justify-center gap-10` 
                : `flex-row items-start justify-between gap-20`,
                `flex w-full max-w-[80%]`
            ]">
                <div :class="[storeAuth.auth.email_verified_at ? `max-w-max` : `max-w-[25rem]`, `flex flex-col`]">
                    <div class="font-extrabold leading-tight text-[50px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Be Part of this Training
                    </div>
                </div>
                <template v-if="storeAuth.auth.id">
                <template v-if="storeAuth.auth.email_verified_at">
                <div>

                    <button
                    v-if="!hasJoined"
                    @click="handleJoinWebinar"
                    class="bg-smicos-secondary hover:bg-smicos-secondary/70 text-white font-semibold py-4 px-10 rounded-full"
                    >
                        Click to participate
                    </button>
                    <!-- UI for current registered -->
                    <div
                        v-if="hasJoined"
                        class="bg-green-500 rounded-md p-4 text-white font-semibold"
                    >
                        You already registered with this training
                    </div>
                </div>
                </template>

                    <AcademyEmailVerify 
                    v-else
                    :academy="academy"
                    :auth="storeAuth.auth"
                    />
                </template>
                
                <template v-else>
                    <AcademyRegistration 
                        v-if="isRegisterForm"
                        :academy="academy"
                        @show-login-form="handleForm"
                    />
                    
                    <AcademyLogin 
                        v-else
                        :academy="academy"
                        @show-register-form="handleForm"
                    />
                </template>
                
            </div>
        </div>

        <!-- other courses -->
        <div class="flex flex-col bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] p-20 z-[2]">
            <div class="flex items-center justify-between">
                <h1 class="text-[40px] text-white font-extrabold">
                    Other Trainings
                </h1>
                <!-- <a
                    href="/academy"
                    class="flex gap-2 rounded-full cursor-pointer bg-smicos-secondary hover:bg-smicos-secondary/80 px-8 py-2"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold max-sm:p-2 uppercase">
                        View more
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a> -->
            </div>
            <div class="flex flex-wrap justify-between items-center">
                <div v-for="academy in filteredAcademy" :key="academy.id">
                    <AcademyCard 
                        cardCss="max-w-[25rem]"
                        buttonCss="bg-smicos-secondary hover:bg-smicos-secondary/80 md:px-10 px-[2rem] md:py-2 py-[0.5rem]"
                        buttonText="Join"
                        :item="academy"
                        :urlRoute="`/academy/${academy.slug}`"
                    />
                </div>
            </div>
        </div>
    </div>
</template>