<script setup>
import { ref, computed, watch } from "vue";
import { vOnClickOutside } from "@vueuse/components";
// import { filterByText } from "@/utils";

import { useFloating, offset, autoUpdate, size } from "@floating-ui/vue";

import {
    ArrowDownIcon
} from "@/components/icons";

const props = defineProps({
    value: { default: "" },
    items: Array,
    // valueKey
    // titleKey
});

const emits = defineEmits(["update-selected"]);

const filteredItems = computed(() => {
    let items = [...props.items, ...customItems.value];

    if (!items.includes(props.value) && props.value) {
        items.push(props.value);
    }

    if (customInput.value) {
        items = items.filter(item => item === customInput.value);
    }

    return items.sort();
});

const isShowDialog = ref(false);
const closeDialog = () => {
    customInput.value = "";
    isShowDialog.value = false;
};

const customItems = ref([]);
const customInput = ref("");

const appendCustomInput = (event) => {
    if (!customInput.value.trim().length) return;

    if (!customItems.value.includes(customInput.value)) {
        customItems.value.push(customInput.value);
    }

    selectOption(customInput.value);
    customInput.value = "";
};

const selectOption = (selected) => {
    isShowDialog.value = false;
    emits("update-selected", selected);
};

// *+> Floating UI
const reference = ref(null);
const floating = ref(null);
const { floatingStyles } = useFloating(reference, floating, {
    placement: "bottom",
    middleware: [
        offset(10),
        size({
            apply({ rects, elements }) {
                Object.assign(elements.floating.style, {
                    width: `${rects.reference.width}px`,
                });
            },
        }),
    ],
    whileElementsMounted: autoUpdate,
});
</script>

<template>
    <div class="font-sans">
        <!-- !!M:update edit profile fonts -->
        <div class="relative font-poppins" ref="reference">
            <input
                @click="isShowDialog = !isShowDialog"
                :value="value"
                type="text"
                class="block w-full p-2 pr-10 border rounded-md cursor-pointer focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 focus-visible:outline-none"
                readonly
            />
            <span
                class="absolute inset-y-0 right-0 flex items-center px-2 text-xs font-extrabold cursor-pointer text-slate-500"
            >
                <ArrowDownIcon class="w-4 text-black" />
            </span>
        </div>
    </div>

    <Teleport to="#outside">
        <div
            v-if="isShowDialog"
            class="flex flex-col gap-y-2 p-2 bg-white rounded-md shadow-lg z-[100] border"
            ref="floating"
            :style="floatingStyles"
            v-on-click-outside="closeDialog"
        >
            <input
                @click.stop=""
                v-model="customInput"
                @keypress.enter="appendCustomInput"
                type="text"
                class="w-full text-sm rounded-md p-2 border"
                placeholder="Search/Others"
            />
            <div
                class="flex flex-col max-w-sm max-h-[10rem] rounded-md overflow-y-auto custom-scrollbar gap-y-1 p-2"
            >
                <span
                    @click.stop="selectOption(item)"
                    v-if="filteredItems.length > 0"
                    v-for="item in filteredItems"
                    class="p-2 rounded hover:bg-gray-200 cursor-pointer"
                >
                    {{ item }}
                </span>
                <span
                    v-else
                    @click.stop="selectOption(customInput)"
                    class="p-2 truncate rounded hover:bg-gray-200 cursor-pointer"
                >
                    {{ customInput }}
                </span>
            </div>
        </div>
    </Teleport>
</template>
