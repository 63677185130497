<script setup>
import { ref, reactive } from "vue";
import {
    MapPinIcon,
    PhoneIcon,
    MailIcon
} from "@components/icons";

import AppointmentSetModal from "@/pages/contact-us/AppointmentSetModal.vue";

// Utils
const validateRules = (event) => {
    const pattern = /^[0-9]*$/;
    if (!pattern.test(event.target.value)) {
        event.target.value = event.target.value.replace(/[^0-9]/g, "");
    }
}

const formData = reactive({
    company_name: "",
    full_name: "",
    email: "",
    contact_no: "",
    subject: "",
    message: ""
});

const errors = ref({});

const submitInquiry = async () => {
    try {
        await window.axios.post(`/send-inquiry`, formData)
    } catch (error) {
        console.error(error);
        console.log(error);
        errors.value = error.response.data.errors;
    }
}

// Smicos connect modal
const appointmentModal = ref(false);
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[750px] xs:h-[600px] h-[400px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[55rem] text-center p-4">
                <div class="md:text-[60px] text-[40px] leading-tight font-extrabold">
                    <span class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">Get Personalized Guidance</span>
                </div>
                <h1 class="text-[#8F8F8F] md:text-[24px] text-[20px]">
                    Tailored 1-on-1 to help you navigate Smicos and achieve success effortlessly.
                </h1>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0 z-1"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20 z-1"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem] z-1"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem] z-1"/>
        </div>

        <!-- Information Section -->
        <div class="flex flex-col items-center justify-center bg-gradient-to-r from-[#31549D] to-[#0A9EDF] md:p-[5rem] p-[2rem] z-[2]">
            <div class="flex justify-center w-full">
                <div class="flex md:flex-row flex-col md:gap-20 gap-10 md:max-w-[80%] w-full">
                    <div class="w-full">
                        <div class="font-extrabold md:text-[40px] text-[34px] md:text-left text-left bg-gradient-to-r from-[#E37C0D] to-[#F2CD59] bg-clip-text text-transparent md:w-[30rem] leading-tight">
                            Get a Personalized Onboarding with Smicos Connect!
                        </div>
                    </div>
                    <div class="w-full">
                        <div class="flex justify-center w-full">
                            <div class="flex justify-center md:w-[430px]">
                                <button
                                    @click="appointmentModal = true"
                                    class="bg-smicos-primary rounded-md py-6 md:px-[5rem] px-[2rem] text-white font-bold md:text-[20px] text-[18px]"
                                >
                                    Book An Appointment
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Google connect -->
        <AppointmentSetModal 
            v-if="appointmentModal"
            @close="appointmentModal = false"
        />
    </div>
</template>