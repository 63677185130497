<template>

    <div class="contactUs_section slide-top pt-[7.5rem]">
        <span class="contactUs_section__subtitle">Contact Us</span>
        <span class="contactUs_section__title">
            INQUIRE NOW
        </span>
        <span class="contactUs_section__subtitle">Got questions? Get in touch with us!</span>
    </div>

    <!-- <div style="display: flex; justify-content: center;">
        <img class="contactUs_section__ico" :src="'/images/homepage/Chat Ico.png'" alt="">
    </div> -->

    <div class="contactUs_section_2">
        <div class="contactUs_section_2__background" alt=""></div>
        <!-- <img class="contactUs_section_2__floatImage" :src="'/images/homepage/SmicosIcon.webp'" alt=""> -->
        <img class="contactUs_section__ico" :src="'/images/homepage/SmicosIcon_blue-R.webp'" alt="">

        <div class="contactUs_section_2__description">
            <v-lazy>
                <div class="contactUs_section_2__contactDetails slide-left">
                    <span class="contactUs_section_2__contactDetails__title">Information</span>
                    <span class="contactUs_section_2__description__details">
                        <v-icon style="margin-top: 12px; width: 55px;" color="gray" :icon="icons.location" size="large"></v-icon>
                        <p>Unit 806, Antel 2000 Corporate Center, Valero St., Salcedo Village, Makati City, 1226, Philippines</p>
                    </span>
                    <span class="contactUs_section_2__description__details">
                        <v-icon style="margin-top: 8px;" color="gray" :icon="icons.phone" size="large"></v-icon>
                        <p><a style="text-decoration: none; color: black" href="tel:09178362680">+63 9178362680</a></p>
                    </span>
                    <span class="contactUs_section_2__description__details">
                        <v-icon style="margin-top: 8px;" color="gray" :icon="icons.email_outline" size="large"></v-icon>
                        <a style="text-decoration: none; color: black;" href="mailto:inquiries@smicos.com">inquiries@smicos.com</a>
                    </span>
                    <span class="contactUs_section_2__description__details">
                        <v-icon style="margin-top: 8px;" color="gray" :icon="icons.email_outline" size="large"></v-icon>
                        <a style="text-decoration: none; color: black;" href="mailto:marketing@smicos.com">marketing@smicos.com</a>
                    </span>
                </div>
            </v-lazy>
            <v-alert
                class="slide-right"
                style="position: fixed; top: 100px; right: 10px;"
                :type="alert.type"
                :text="alert.text"
                v-model="alert.status"
            ></v-alert>
            <v-lazy>
                <div class="contactUs_section_2__contactForm slide-right">
                    <span class="contactUs_section_2__contactForm__title">GET IN TOUCH WITH US</span>
                    <span class="contactUs_section_2__contactForm__subtitle"></span>
                    <v-text-field :error-messages="errors.company_name" v-model="formData.company_name" :append-inner-icon="icons.office" label="Company Name"></v-text-field>
                    <v-text-field :error-messages="errors.full_name" v-model="formData.full_name" :append-inner-icon="icons.name" label="Full Name"></v-text-field>
                    <v-text-field :error-messages="errors.email" v-model="formData.email" :append-inner-icon="icons.email" type="email" label="Email"></v-text-field>
                    <v-text-field :error-messages="errors.contact_no" v-model="formData.contact_no" :append-inner-icon="icons.phoneBold" label="Contact No."></v-text-field>
                    <v-text-field :error-messages="errors.subject" v-model="formData.subject" :append-inner-icon="icons.name" label="Subject"></v-text-field>
                    <v-textarea :error-messages="errors.message" v-model="formData.message" :append-inner-icon="icons.message" label="Message"></v-textarea>
                    <input class="contactUs_section_2__contactForm__button" @click="sendInquiry" type="button" value="SUBMIT">

                    <br>
                </div>
            </v-lazy>
        </div>
        <!-- <div style="background-color: #FFE8E8; width: 100%; position: absolute;" id="bg-small"></div> -->
    </div>

    <div class="flex justify-center w-full pb-20 bg-[#FFE8E8]">
        <div class="flex items-start justify-between w-full max-w-[55%]">
            <div class="font-extrabold leading-tight text-[40px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent w-[30rem]">
                Get a Personalized Onboarding with Smicos Connect!
            </div>
            <div class="flex justify-center w-[430px]">
                <button
                    @click="appointmentModal = true"
                    class="bg-gradient-to-r from-[#384590] to-[#01ADED] rounded-md py-6 px-20 text-white font-bold text-[20px]"
                >
                    Book An Appointment
                </button>
            </div>
        </div>
    </div>

    <AppointmentSetModal 
        v-if="appointmentModal"
        @close="appointmentModal = false"
    />

</template>
<script>
import {
    mdiPhoneOutline ,
    mdiMapMarkerOutline,
    mdiOfficeBuilding,
    mdiAccount,
    mdiEmail,
    mdiPhone,
    mdiMessage,
    mdiEmailOutline

} from '@mdi/js';
import AppointmentSetModal from "@/pages/contact-us/AppointmentSetModal.vue";

export default {
props: [
    'recaptchasitekey'
],
components: {
    AppointmentSetModal
},
data() {
  return {
    icons: {
        phone: mdiPhoneOutline ,
        phoneBold: mdiPhone,
        location: mdiMapMarkerOutline,
        office: mdiOfficeBuilding,
        name: mdiAccount,
        email: mdiEmail,
        email_outline: mdiEmailOutline,
        message: mdiMessage
    },
    formData: {
        company_name: '',
        full_name: '',
        email: '',
        contact_no: '',
        subject: '',
        message: '',
    },
    errors: {},
    alert: {
        status: false,
        text: '',
        type: '',
    },
    appointmentModal: false
  }
},
created(){

},
mounted(){

},
methods: {
    sendInquiry(){
    // Execute reCAPTCHA and handle the token
    //   grecaptcha.execute(this.recaptchasitekey, { action: "send_inquiry" }).then((token) => {
            axios.post('/send-inquiry', {
                company_name: this.formData.company_name,
                full_name: this.formData.full_name,
                email: this.formData.email,
                contact_no: this.formData.contact_no,
                subject: this.formData.subject,
                message: this.formData.message,
                // recpatchaToken: token,
            })
            .then(response => {
                this.alert.status = true;
                this.alert.text = response.data.message;
                this.alert.type = response.data.type;
                this.formData = {};
                setTimeout(() => {
                    this.alert.status = false;
                }, 3000);
            })
            .catch(error => {
                this.errors = error.response.data.errors;
                setTimeout(() => {
                    this.errors = {};
                }, 5000);
            });
    //   });

    },
},
watch: {

},
computed: {

},
}
</script>
