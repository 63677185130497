<script setup>
import { reactive } from 'vue';
import axios from 'axios';
import { useAuthStore } from "@/stores/Auth";

const props = defineProps(['academy']);
const emits = defineEmits(['show-register-form', 'login']);

const storeAuth = useAuthStore();

const credentials = reactive({
    email: "",
    password: "",
    device_name: "browser"
})

const handleLogin = async () => {
    try {
        credentials.training = {
            id: props.academy.id,
            title: props.academy.title,
            slug: props.academy.slug,
            path: props.academy.fullUrlPath,
            published_date: props.academy.published_date,
        }
        
        const res = await axios.post('https://app.smicos.com/api/website/sanctum/login', credentials, {
             headers: {
                'Content-Type': 'application/json',  // Specify that you're sending JSON
                'Accept': 'application/json',        // Specify that you expect a JSON response
            }
        });
        storeAuth.auth = res.data.user;
        storeAuth.auth.api_token = res.data.token;
        localStorage.setItem("user", JSON.stringify(storeAuth.auth));
    } catch (error) {
        console.error(error);
    }
}
</script>

<template>
    <div class="flex-1 flex justify-center md:p-10 p-6 rounded-md border shadow-md md:max-w-[50rem] w-full">
        <div 
            @keypress.enter="handleLogin"
            class="flex flex-col items-center gap-6 md:w-[60%] w-full"
        >
            <span class="font-extrabold leading-tight text-[25px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent mb-8">
                Sign in
            </span>
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Business Email
                </span>
                <input 
                    v-model="credentials.email"
                    type="email" 
                    placeholder="Email" 
                    class="border p-2 rounded-md w-full"
                    autocomplete="email"
                >
            </div>
            <div class="flex flex-col gap-2 w-full">
                <span class="font-semibold">
                    Password
                </span>
                <input 
                    v-model="credentials.password"
                    type="password" 
                    placeholder="Password" 
                    class="border p-2 rounded-md w-full"
                >
            </div>
            <button 
                @click="handleLogin"
                class="bg-smicos-primary hover:bg-smicos-primary/80 rounded-md px-6 py-2 text-white w-full"
            >
                Sign in
            </button>
            <div class="flex md:flex-row flex-col items-center gap-2">
                <span class="font-semibold">
                    Need a new account?
                </span> 
                <button 
                    @click="emits('show-register-form')"
                    class="bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent font-bold hover:opacity-70 hover:underline hover:underline-offset-3"
                >
                    Sign up for free
                </button>
            </div>
        </div>
    </div>
</template>