<script setup>
import { ref, computed } from "vue";
import {
    Quote2Icon,
    ArrowUpRightIcon
} from "@components/icons";

import EventsCard from "@components/events/EventsCard.vue";

const props = defineProps(['events']);

// utils
const currentMonth = moment().format("MMMM");
const todayDate = moment().startOf().format("YYYY-MM-DD HH:mm:ss");

const parseTextContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html').body.textContent || "";

    const sentences = doc.split('. ');
    const firstSentence = `${sentences[0]}.`;

    if (firstSentence.length > 150) {
      return firstSentence.substring(0, 150) + '...';
    }

    return firstSentence;
}

const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

// Filter Events
const eventItems = ref(3);

const handleLoadMore = () => {
    eventItems.value = eventItems.value + 3;
}

const allEvents = computed(() => {
    let items = props.events;
    if(items.length < 1) return [];

    items = items.sort((a, b) => b.end_at.localeCompare(a.end_at));

    return items;
})

const upcomingEvents = computed(() => {
    let items = props.events;
    if(items.length < 1) return [];

    items = items.filter(item => item.start_at > todayDate).slice(0, 3);

    return items;
});

const recentEvents = computed(() => {
    let items = props.events;
    if(items.length < 1) return [];

    items = items.filter(item => item.start_at < todayDate)
        .sort((a, b) => b.end_at.localeCompare(a.end_at))
        .slice(0, 3);

    return items;
});

const eventStatus = (start) => {
    if (start > todayDate) return "upcoming";

    return "recent";
}
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px]">
            <div class="flex flex-col items-center justify-center gap-4 max-w-[65rem] text-center px-4">
                <h1 class="md:text-[64px] xs:text-[40px] text-[35px] leading-tight font-extrabold">
                    Better Conversations, Better Business
                </h1>
                <span class="text-[#8F8F8F] md:text-[24px] text-[20px]">
                    Join our webinars and learn how AI-powered chatbot can elevate customer management and drive business results. Register now and start building better customer relationships!
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0 z-[1]"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20 z-[1]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem] z-[1]"/>
            <img :src="`/images/figures/circle-orange.png`" class="absolute -left-[20rem] -bottom-[20rem] z-[1]"/>
        </div>
        <!-- upcoming events -->
        <div class="flex flex-col 2xl:p-[5rem] md:p-[2rem] p-[1rem] bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] z-[2]">
            <div 
                class="flex md:flex-row flex-col md:gap-14 gap-2"
                :class="[upcomingEvents.length > 0 ? `items-start` : `items-center`]"
            >
                <div class="flex flex-col justify-center text-white w-full md:max-w-[15rem] xs:gap-5 gap-2 max-sm:text-center">
                    <h1 class="md:text-[40px] text-[35px] leading-tight font-extrabold">
                        Upcoming Events
                    </h1>
                    <div class="w-full border-[1px] border-white"></div>
                </div>
                <div 
                    v-if="upcomingEvents.length > 0"
                    class="flex flex-wrap justify-start xl:gap-10 md:gap-5"
                >
                    <div v-for="event in upcomingEvents">
                        <EventsCard
                            :event="event"
                            :urlRoute="`/events/upcoming/${event.id}`"
                        />
                    </div>
                </div>
                <span 
                    v-else
                    class="flex items-center justify-center w-full font-bold xs:text-[25px] text-[20px] max-xxs:text-center leading-tight text-white"
                >   
                    No upcoming events listed
                </span>
            </div>
        </div>
        <!-- recent events -->
        <div class="flex flex-col 2xl:p-[5rem] md:p-[2rem] p-[1rem]">
            <div 
                class="flex md:flex-row flex-col md:gap-14"
                :class="[upcomingEvents.length > 0 ? `items-start` : `items-center`]"
            >
                <div class="flex flex-col justify-center md:max-w-[15rem] w-full gap-6 max-sm:text-center">
                    <h1 class="md:text-[40px] text-[35px] leading-tight font-extrabold bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Recent Events
                    </h1>
                    <div class="w-full h-[3px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D]"></div>
                </div>
                <div 
                    v-if="recentEvents.length > 0"
                    class="flex flex-wrap justify-start xl:gap-10 md:gap-5"
                >
                    <div v-for="event in recentEvents">
                        <EventsCard
                            :event="event"
                            :urlRoute="`/events/recent/${event.id}`"
                            buttonText="Read More"
                            buttonCss="bg-smicos-primary hover:bg-smicos-primary/80 md:px-[1.5rem] px-[0.75rem] py-2"
                        />
                    </div>
                </div>
                <span 
                    v-else
                    class="flex items-center justify-center w-full font-bold text-[25px]"
                >   
                    No recent events listed
                </span>
            </div>
        </div>
        <!-- all events -->
        <div class="flex flex-col 2xl:p-[5rem] md:p-[2rem] p-[1rem] bg-gradient-to-r from-[#0A9EDF] to-[#31549D]">
            <div 
                class="flex md:flex-row flex-col md:gap-14"
                :class="[upcomingEvents.length > 0 ? `items-start` : `items-center`]"
            >
                <div class="flex flex-col justify-center w-full md:max-w-[15rem] gap-6 text-white max-sm:text-center xs:pt-[2.5rem] pt-[1rem]">
                    <h1 class="md:text-[40px] text-[35px] leading-tight font-extrabold">
                        All Events
                    </h1>
                    <div class="w-full h-[3px] bg-white"></div>
                </div>
                <div 
                    v-if="allEvents.length > 0"
                    class="flex flex-wrap justify-start w-full xl:gap-10 md:gap-2"
                >
                    <div 
                        v-for="event in allEvents.slice(0, eventItems)"
                        class="relative flex flex-col items-center md:min-w-[25rem] md:max-w-[25rem]"
                    >
                        <EventsCard
                            :event="event"
                            :urlRoute="`/events/recent/${event.id}`"
                            :buttonText="event.start_at < todayDate ? `Read More` : `Register`"
                            :buttonCss="event.start_at < todayDate 
                                ? `bg-smicos-primary hover:bg-smicos-primary/80 md:px-[1.5rem] px-[0.75rem] py-2` 
                                : `bg-smicos-secondary hover:bg-smicos-secondary/80 md:px-[2rem] px-[1.25rem] py-2`"
                        />
                    </div>
                    <button
                        v-if="allEvents.length > eventItems"
                        @click="handleLoadMore"
                        class="bg-[#0A9EDF] hover:bg-[#0A9EDF]/80 md:w-[90%] w-full rounded-full p-2 text-center text-white font-bold text-[20px]"
                    >
                        Load More
                    </button>
                </div>
                <span 
                    v-else
                    class="flex items-center justify-center w-full font-bold text-[25px] text-white"
                >   
                    No event found
                </span>
            </div>
        </div>
    </div>
</template>