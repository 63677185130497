<script setup>
import { ref, computed, onMounted } from "vue";
import {
    CalendarIcon,
    CalendarPlusIcon,
    ClockIcon,
    MapPinIcon,
    MegaphoneIcon
} from "@components/icons";

import EventsCard from "@components/events/EventsCard.vue";
import EventsRecentCard from "@components/events/EventsRecentCard.vue";

const props = defineProps(['event', 'recents']);

// utils
const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY, ddd");
}

const formatTime = (start, end) => {
    return moment(start).format("hh:mm A")+" - "+moment(end).format("hh:mm A")
}

const youtubeIframeLoaded = ref(true);
const youtubeIframeSrc = ref("");

onMounted(() => {
    // Lazy load YouTube iframe
    const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
        youtubeIframeSrc.value = "https://www.youtube-nocookie.com/embed/videoseries?si=e9zTzXSXMhLJ7xb_&amp;list=PLJTADOU03T0owxkosHXtElV0WF68sKnGz";
        youtubeIframeLoaded.value = true;
        observer.disconnect();
        }
    });

    observer.observe(document.querySelector(".yt-video"));
});
</script>

<template>
    <div class="flex flex-col">
        <!-- banner -->
        <div 
            class="relative flex items-center justify-center 2xl:h-[900px] lg:h-[750px] h-[600px] bg-[#0A9EDF]"
            style="background: rgba(10, 158, 223, 1) url('/images/events/banner/banner-1.png') no-repeat; background-size: cover; background-position: center;" 
        >
            <div class="flex flex-col items-center justify-center gap-4 max-w-[65rem] text-center p-6">
                <h1 class="md:text-[64px] text-[40px] leading-tight font-extrabold text-white">
                    Better Conversations, Better Business
                </h1>
                <span class="text-[#8F8F8F] md:text-[24px] text-[20px] text-white">
                    Join our webinars and learn how AI-powered chatbot can elevate customer management and drive business results. Register now and start building better customer relationships!
                </span>
            </div>

            <!-- float figures -->
            <img :src="`/images/figures/particles-left.png`" class="absolute left-0 top-0"/>
            <img :src="`/images/figures/particles-right.png`" class="absolute right-0 -bottom-20"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -right-[20rem] -top-[20rem]"/>
            <img :src="`/images/figures/circle-blue.png`" class="absolute -left-[20rem] -bottom-[20rem]"/>
        </div>

        <!-- calendar section -->
        <div class="flex justify-center md:p-[5rem] p-[2rem] bg-gradient-to-r from-[#0A9EDF] to-[#31549D] z-[2]">
            <div class="flex md:flex-row flex-col items-center justify-between md:gap-0 gap-10 w-full max-w-[80%]">
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <CalendarIcon class="w-6"/>
                        <span>
                            Date
                        </span>
                    </div>
                    <div class="font-bold">
                        {{ formatDate(event.start_at) }}
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <ClockIcon class="w-6"/>
                        <span>
                            Time
                        </span>
                    </div>
                    <div class="font-bold">
                        {{ formatTime(event.start_at, event.end_at) }}
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col gap-4 text-white min-w-[15rem]">
                    <div class="flex items-center gap-2">
                        <MapPinIcon class="w-6"/>
                        <span>
                            Platform
                        </span>
                    </div>
                    <div class="font-bold">
                        Zoom, Facebook Live
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
            </div>
        </div>

        <!-- title description -->
        <div class="flex md:justify-center gap-20 md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col items-start md:justify-between w-full md:gap-20 gap-6 md:max-w-[80%]">
                <div class="flex flex-col gap-4 md:max-w-[15rem] w-full">
                    <div class="font-extrabold leading-tight text-[28px] text-center bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Event Summary
                    </div>
                    <div class="h-[2px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D]"></div>
                </div>
                <div class="flex-1">
                    {{ event.event_summary }}
                </div>
            </div>
        </div>

        <!-- webinar speakers -->
        <div class="flex justify-center gap-20 bg-gradient-to-r from-[#F2CD59] to-[#E37C0D] md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col items-start justify-between w-full md:gap-20 gap-6 md:max-w-[80%]">
                <div class="flex flex-col gap-4 max-w-[15rem]">
                    <div class="font-extrabold leading-tight text-[28px] text-white">
                        Webinar Spearkers
                    </div>
                    <div class="h-[1px] bg-white"></div>
                </div>
                <div class="flex flex-col">
                    <div class="flex-1 flex md:flex-row flex-col md:gap-10">
                        <div v-for="index in 3">
                            <EventsRecentCard />
                        </div>
                    </div>
                    <div class="text-white text-[20px]">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </div>
                </div>
            </div>
        </div>

        <!-- other content -->
        <div class="flex md:justify-center gap-20 md:p-[5rem] p-[2rem]">
            <div class="flex md:flex-row flex-col items-start justify-between w-full md:gap-20 gap-6 md:max-w-[80%]">
                <div class="flex flex-col gap-4 md:max-w-[15rem] md:min-w-[15rem] w-full">
                    <div class="font-extrabold leading-tight text-[28px] text-center bg-gradient-to-r from-[#E1251B] to-[#E37C0D] bg-clip-text text-transparent">
                        Other Content
                    </div>
                    <div class="h-[1px] bg-gradient-to-r from-[#E1251B] to-[#E37C0D]"></div>
                </div>
                <div class="flex flex-col w-full">
                    <div v-if="youtubeIframeLoaded" class="relative md:top-2 yt-video slide-right w-full">
                        <iframe
                            class="w-full h-full rounded-xl"
                            :src="youtubeIframeSrc"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin"
                            allowfullscreen
                        ></iframe>
                    </div>
                    <div>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                        <br><br>
                        Ut enim ad minim veniam: quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                        Duis aute irure dolor in reprehenderit: in voluptate velit esse cillum dolore eu fugiat nulla pariatur
                        Excepteur sint occaecat cupidatat non proident: sunt in culpa qui officia deserunt mollit anim id est laborum.
                        <br><br>
                        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.
                    </div>
                </div>
            </div>
        </div>

        <!-- all events -->
        <div class="flex flex-col md:p-[5rem] p-[2rem] bg-gradient-to-r from-[#0A9EDF] to-[#31549D]">
            <div class="flex flex-wrap justify-center md:gap-10">
                <div v-for="recent in recents">
                    <EventsCard
                        :event="recent"
                        :urlRoute="`/events/recent/${recent.id}`"
                        buttonText="View more"
                        buttonCss="bg-smicos-primary hover:bg-smicos-primary/80 md:px-5 px-3 py-2"
                    />
                </div>
            </div>
        </div>
    </div>
</template>