<script setup>
import { reactive, ref } from 'vue';

const formData = reactive({
    email: "",
    age: "",
    first_name: "",
    last_name: "",
    location: "",
    career: "",
    industry: "",
    is_first_time: "",
    finding: "",
    speaker: "",
    suggestions: "",
    satisfaction: ""
});

const ages = [
  "12-27 years old",
  "28-43 years old",
  "44-59 years old",
  "60-69 years old",
  "70-78 years old",
  "79 years old and above"
];

const industries = [
    "Agriculture",
    "Apparel & Fashion",
    "Automotive",
    "Banking",
    "Biotechnology",
    "Chemicals",
    "Construction",
    "Consulting",
    "Education",
    "Electronics",
    "Energy",
    "Engineering",
    "Entertainment",
    "Environmental",
    "Finance",
    "Food & Beverage",
    "Government",
    "Healthcare",
    "Hospitality",
    "Insurance",
    "Manufacturing",
    "Marketing",
    "Media",
    "Non-profit",
    "Pharmaceuticals",
    "Real Estate",
    "Retail",
    "Software",
    "Technology",
    "Telecommunications",
    "Transportation",
    "Utilities"
];

const locations = [
    "Abra",
    "Agusan del Norte",
    "Agusan del Sur",
    "Aklan",
    "Albay",
    "Antique",
    "Apayao",
    "Aurora",
    "Basilan",
    "Bataan",
    "Batanes",
    "Batangas",
    "Benguet",
    "Biliran",
    "Bohol",
    "Bukidnon",
    "Bulacan",
    "Cagayan",
    "Camarines Norte",
    "Camarines Sur",
    "Camiguin",
    "Capiz",
    "Catanduanes",
    "Cavite",
    "Cebu",
    "Compostela Valley",
    "Cotabato",
    "Davao del Norte",
    "Davao del Sur",
    "Davao Occidental",
    "Davao Oriental",
    "Dinagat Islands",
    "Eastern Samar",
    "Guimaras",
    "Ifugao",
    "Ilocos Norte",
    "Ilocos Sur",
    "Iloilo",
    "Isabela",
    "Kalinga",
    "La Union",
    "Laguna",
    "Lanao del Norte",
    "Lanao del Sur",
    "Leyte",
    "Maguindanao",
    "Marinduque",
    "Masbate",
    "Metro Manila",
    "Misamis Occidental",
    "Misamis Oriental",
    "Mountain Province",
    "Negros Occidental",
    "Negros Oriental",
    "Northern Samar",
    "Nueva Ecija",
    "Nueva Vizcaya",
    "Occidental Mindoro",
    "Oriental Mindoro",
    "Palawan",
    "Pampanga",
    "Pangasinan",
    "Quezon",
    "Quirino",
    "Rizal",
    "Romblon",
    "Samar",
    "Sarangani",
    "Siquijor",
    "Sorsogon",
    "South Cotabato",
    "Southern Leyte",
    "Sultan Kudarat",
    "Sulu",
    "Surigao del Norte",
    "Surigao del Sur",
    "Tarlac",
    "Tawi-Tawi",
    "Zambales",
    "Zamboanga del Norte",
    "Zamboanga del Sur",
    "Zamboanga Sibugay"
];

const careers = [
    "Professional",
    "Business Owner",
    "Student"
]

const findings = [
    "Social Media (Facebook, Instagram, Twitter, etc.)",
    "Google Search",
    "Blog/Article",
    "Word of Mouth",
    "Other"
]

const satisfactions = [
    "Dissatisfied",	
    "Slightly Dissatisfied",	
    "Satisfied",	
    "Very Satisfied"
]

 const criteria = [
    "Overall mastery of the topic",	
    "Proficiency on the coverage of subject matter",	
    "Credibility as a source speaker",
    "Clarity: Points were made clearly and without unnecessary details",	
    "Pace of delivery"
]

const experiences = [
    "I would recommend this event to a friend or colleague.",	
    "My overall webinar experience was very fruitful.",	
    "The time allotted for the event was adequate.",	
    "The webinar ran smoothly and without any disruptions.",	
    "I can confidently say that I have learned something beneficial from this webinar.",	
    "The learnings from this webinar have empowered me to take action in integrating AI into customer management"
]

const selectedRadioButton = ref(false);
</script>

<template>
    <div class="flex flex-col items-center pt-[7.5rem] border-b">
        <img 
            :src="'/images/academy/banner/transforming-digital-marketing-with-ai.png'" 
            alt="training banner" 
            class=" w-full max-w-screen-lg"
        >
        <div class="flex flex-col gap-6 pt-10 pb-10 w-full max-w-screen-lg">
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Email
                    </span>
                    <input 
                        v-model="formData.email"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="Email address"
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Age
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.age = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="age, index in ages" 
                                :value="age"
                                :key="index"
                                class="bg-white"
                            >
                                {{ age }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        First name
                    </span>
                    <input 
                        v-model="formData.first_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="First name"
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Last name
                    </span>
                    <input 
                        v-model="formData.last_name"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="Last name"
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Location
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.location = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="location, index in locations" 
                                :value="location"
                                :key="index"
                                class="bg-white"
                            >
                                {{ location }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Select the option that best describes you
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.career = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="career, index in careers" 
                                :value="career"
                                :key="index"
                                class="bg-white"
                            >
                                {{ career }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Nature of business
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.industry = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="industry, index in industries" 
                                :value="industry"
                                :key="index"
                                class="bg-white"
                            >
                                {{ industry }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Is it your first time attending a webinar hosted by Smicos?
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.career = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                class="bg-white"
                                value="Yes"
                            >
                                Yes
                            </option>
                            <option 
                                class="bg-white"
                                value="No"
                            >
                                No
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>

            <!-- Speakers satisfaction -->
            <div class="flex flex-col gap-10 py-4">
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            SPEAKER ONE:
                        </span>
                        <span class="text-sm">
                            How would you rate the overall presentation given by <b>Patti Germentil</b> on the topic of <b>Changing the Digital Marketing Landscape with AI</b> based on the criteria listed below?
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center text-center gap-4">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in satisfactions"
                            class="w-full font-bold text-sm"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in criteria"
                        class="flex items-center gap-4"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction, index in satisfactions"
                            class="w-full text-center"
                        >
                            <input
                                type="radio"
                                :name="`speaker1-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            SPEAKER TWO:
                        </span>
                        <span class="text-sm">
                            How would you rate the overall presentation given by <b>Kayla Ambayec</b> on the topic of <b>Changing the Digital Marketing Landscape with AI</b> based on the criteria listed below?
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center gap-4 text-center">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in satisfactions"
                            class="w-full font-semibold"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in criteria"
                        class="flex items-center justify-center gap-4 text-center"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction, index in satisfactions"
                            class="w-full"
                        >
                            <input
                                type="radio"
                                :name="`speaker2-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
                <div class="flex flex-col gap-6">
                    <div class="flex flex-col">
                        <span class="font-bold">
                            WEBINAR EXPERIENCE:
                        </span>
                    </div>
                    <!-- header -->
                    <div class="flex items-center justify-center gap-4 text-center">
                        <div class="w-full"></div>
                        <div
                            v-for="satisfaction in satisfactions"
                            class="w-full font-semibold"
                        >
                            {{ satisfaction }}
                        </div>
                    </div>
                    <!-- body -->
                    <div
                        v-for="item in experiences"
                        class="flex items-center justify-center gap-4 text-center"
                    >
                        <div class="w-full text-left">
                            {{ item }}
                        </div>
                        <div
                            v-for="satisfaction in satisfactions"
                            class="w-full"
                        >
                            <input
                                type="radio"
                                :name="`experience-${item}`"
                                class="w-6 h-6 appearance-none border-2 border-solid border-gray-400 rounded-full checked:bg-blue-600 checked:border-transparent focus:outline-none cursor-pointer"
                            >
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="flex items-center gap-6 w-full">
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        How did you hear about Smicos?
                    </span>
                    <div class="border rounded-md p-2 cursor-pointer w-full">
                        <select 
                            @change="($event) => formData.is_first_time = $event.target.value"
                            class="appearance-auto w-full appearance-none outline-none cursor-pointer"
                        >
                            <option 
                                v-for="finding, index in findings" 
                                :value="finding"
                                :key="index"
                                class="bg-white"
                            >
                                {{ finding }}
                            </option>
                        </select>
                    </div>
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
                <div class="relative flex flex-col gap-2 w-full">
                    <span class="font-semibold text-sm">
                        Additional question
                    </span>
                    <input 
                        v-model="formData.speaker"
                        type="text"
                        class="border w-full rounded-md p-2 focus:outline-smicos-primary"
                        placeholder="Do you have any additional questions for any of the speakers? You may send them here."
                    >
                    <!-- <span
                        v-if="errors.email" 
                        class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                    >
                        {{ errors.email[0] }}
                    </span> -->
                </div>
            </div>
            <div class="relative flex flex-col gap-2 w-full">
                <span class="font-semibold text-sm">
                    Are there any topics you would like to see for our future webinars?
                </span>
                <textarea 
                    v-model="formData.suggestions"
                    rows="10"
                    class="border rounded-md resize-none p-2 focus:outline-smicos-primary"
                    placeholder="Are there any topics you would like to see for our future webinars?"
                >
                </textarea>
                <!-- <span
                    v-if="errors.email" 
                    class="absolute top-[100%] text-smicos-secondary text-sm font-bold"
                >
                    {{ errors.email[0] }}
                </span> -->
            </div>

            <div class="flex items-center justify-center">
                <button class="bg-smicos-primary p-4 rounded-lg text-white font-semibold w-[20rem]">
                    Submit
                </button>
            </div>
        </div>
    </div>
</template>