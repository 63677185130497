<script setup>
import {
    Quote2Icon,
    ArrowUpRightIcon
} from "@components/icons";

// Utils
const parseTextContent = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html').body.textContent || "";

    const sentences = doc.split('. ');
    const firstSentence = `${sentences[0]}.`;

    if (firstSentence.length > 150) {
      return firstSentence.substring(0, 150) + '...';
    }

    return firstSentence;
}

const formatDate = (date) => {
    return moment(date).format("MMMM DD, YYYY");
}

const props = defineProps({
    event: Object,
    urlRoute: String,
    buttonText: { default: "Register" },
    buttonCss: { default: "bg-smicos-secondary hover:bg-smicos-secondary/80 md:px-[2rem] px-[1.25rem] py-2" },
});
</script>

<template>
    <div class="relative flex flex-col items-center md:min-w-[25rem] md:max-w-[25rem]">
        <div class="relative top-5 rounded-t-3xl border-[1px] border-[#00AEEE] w-full h-[13rem]">
            <img :src="`${event.banner}`" alt="card banner" class="h-full w-full rounded-xl object-cover">
        </div>
        <div class="relative bottom-5 w-full max-h-[20.5rem]">
            <Quote2Icon class="w-full h-full" />
            <div class="absolute top-0 left-0 flex flex-col justify-start gap-4 md:!p-8 p-4 max-sm:text-sm h-[16rem]">
                <span class="text-[20px] font-bold">
                    {{ event.title }}
                </span>
                <span>
                    {{ parseTextContent(event.description) }}
                </span>
            </div>
            <div class="absolute bottom-0 left-0 w-full flex items-center justify-between md:pl-[2rem] pl-[1rem] pb-2">
                <div class="text-[16px] text-[#77777A]">
                    {{ formatDate(event.start_at) }}
                </div>
                <a
                    :href="urlRoute"
                    :class="[buttonCss, `flex gap-2 rounded-full cursor-pointer`]"
                >
                    <span class="text-[#FFF] max-sm:text-sm font-bold uppercase">
                        {{ buttonText }}
                    </span>
                    <ArrowUpRightIcon class="w-5"/>
                </a>
            </div>
        </div>
    </div>
</template>