import './bootstrap';
import { createApp } from 'vue';
import { createPinia } from 'pinia';

//VUETIFY
import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import { aliases, mdi } from "vuetify/iconsets/mdi-svg";
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

import VueParticles from "vue3-particles";

// PINIA
const pinia = createPinia();

//LAYOUTS
import navbar from './components/layouts/navbar.vue';
import footerchat from './components/layouts/footer.vue';

//MODULES
import homepage from './components/modules/homepage.vue';
import solutions from './components/modules/solutions.vue';
// import aboutus from './components/modules/about-us.vue';
// import contactus from './components/modules/contact-us.vue';
import demo from './components/modules/demo.vue';
import support from './components/modules/support.vue';
import pricing from './components/modules/pricing.vue';
import tcpp from './components/modules/terms-condition-privacy-policy.vue';
import eula from './components/modules/eula.vue';
import webinarregistration from './components/modules/webinar-registration.vue';

// Refactor routes
import Blogs from '@/pages/blogs/Blogs.vue';
import BlogsView from '@/pages/blogs/BlogsView.vue';

import Events from '@/pages/events/Events.vue';
import EventsUpcoming from '@/pages/events/EventsUpcoming.vue';
import EventsRecent from '@/pages/events/EventsRecent.vue';

import Academy from '@/pages/academy/Academy.vue';
import AcademyView from '@/pages/academy/AcademyView.vue';
import AcademyList from '@/pages/academy/AcademyList.vue';

import AboutUs from '@/pages/about-us/AboutUs.vue';

import ContactUs from '@/pages/contact-us/ContactUs.vue';
import SurveyForm from '@/pages/SurveyForm.vue';

import Faqs from '@/pages/Faqs.vue';

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: "mdi",
        aliases,
        sets: {
            mdi,
        },
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: "#8a3d92",
                },
            }
        },
    },
})


createApp(
{
    components:{
        //Layout
        navbar,
        footerchat,

        //Modules
        homepage,
        solutions,
        demo,
        pricing,
        tcpp,
        eula,
        webinarregistration,
        support,

        // Refactor routes
        Blogs,
        BlogsView,

        Events,
        EventsUpcoming,
        EventsRecent,

        Academy,
        AcademyView,
        AcademyList,

        AboutUs,

        ContactUs,
        
        SurveyForm,

        Faqs
    }
}).use(vuetify).use(VueParticles).use(pinia).mount('#app');
